import React from "react";
import { Card, Row, Col, Layout, Image } from "antd";
import HeaderComponent from "../../components/Header";
import { useLocation } from "react-router-dom";

const { Content } = Layout;

// const imageProp = () => {
//     return (
//         <Image src='images/tickvector.svg' height={24} />
//     )
// }

export default function EmailVerified() {

    const params = useLocation();

    console.log('params', params)

    const name = params?.state?.name;
   // const email = params?.state?.email;

    return (
        <div className="antdContainer">
            <Layout>
                <HeaderComponent />
                <Content style={{ height: '100vh', backgroundColor: 'rgb(200 221 254)', paddingTop: '4em' }}>
                    <Row justify="center">
                        <Col span={8}>
                            <Card>
                                <Row justify="center">
                                    <Image src="images/email-success.svg" height={120} preview={false} />
                                </Row>
                                <Row justify="center" >
                                    <h1 style={{
                                        paddingTop: 12,
                                        fontSize: 24
                                    }}>Verification Success! </h1>
                                </Row>
                                <Row justify="center" style={{ paddingTop: 4, paddingBottom: 8 }}>
                                    <Col span={22}>
                                        <p style={{ textAlign: 'center' }}>Hi, {name} your email is verified successfully. You can login to the app</p>
                                    </Col>
                                </Row>
                                <Row justify="center">
                                    <Col span={22}>
                                        <p style={{ textAlign: 'center ' }}>If you have any questions or comments just email us at contact@onelearning.app we would love to hear from you.</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </div>
    )

}


