import { languageCode } from "../../app/constants";


export const AppLanguageCaptions = {
    [languageCode.ro]: {
        "Download now to view this play moment...": "Descarcă acum pentru a vizualiza această activitate",
        "Spend quality family time": "Petrece timp de calitate în familie",
        "Learn and grow": "Învață și dezvoltă abilități de viață prin joacă",
        "Experience the magic of play": "Descoperă magia jocului"
    },
    [languageCode.en]: {
        "Download now to view this play moment...": "Download now to view this play moment...",
        "Spend quality family time": "Spend quality family time",
        "Learn and grow": "Learn and grow",
        "Experience the magic of play": "Experience the magic of play"
    }
}