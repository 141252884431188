import { useNavigate, useParams } from "react-router-dom"
import { StringParam, useQueryParam } from "use-query-params";

import DefaultFooter from "../../components/DefaultFooter"
import DefaultHeader from "../../components/DefaultHeader"
import ShareView from "./share"
import './style.scss'
import { useEffect, useMemo, useState } from "react";
import { languageCode, typeOS } from "../../app/constants";
import { AppLanguageCaptions } from "./constants";
import { getMobileOperatingSystem } from "../DownloadRedirect/En";



const ShareIndex = ({ openInApp }) => {


    let { id: paramId } = useParams();
    const [id] = useQueryParam('id', StringParam);
    const navigate = useNavigate();

    const playmomentId = paramId ?? id

    const [language, setPlaymomentLanguage] = useState(languageCode.en)

    const translate = useMemo(() => {

        const languageCaptions = AppLanguageCaptions?.[language] ?? AppLanguageCaptions.en

        // can pass object with keys for faster/simpler access

        return (key) => languageCaptions?.[key] ?? key

    }, [language])

    useEffect(() => {

        //console.log('typeof playmoment id', typeof paramId, typeof id, paramId, id)
        const passedId = +playmomentId
        const isValid = typeof passedId === 'number' && passedId > 0
        if (!isValid) {
            navigate({
                pathname: '/',
                //search: location.search,
            });
            // setAppUrl(undefined)
        }
        //else {
        //setAppUrl(`https://www.onelearning.app/onekidsapp/challengeDetails/${playmomentId}`)
        //}

    }, [playmomentId])

    // const onUseApp = () => {
    //     //e.preventDefault()
    //     window.location = `https://launch.onelearning.app/onekidsapp/challengeDetails/${playmomentId}`
    // }

    //const os = getMobileOperatingSystem()
    //const showOpenAppButton = (os !== typeOS.ios && openInApp)
    const showOpenAppButton = openInApp

    return (
        <div className="Home share-home">
            <div className="WhiteSection HomeSection">
                <DefaultHeader
                    hideDownload
                    hideLanguage
                    openAppProp={
                        showOpenAppButton ?
                            (
                            <div className="open-app">
                                <a href={`https://launch.onelearning.app/onekidsapp/challengeDetails/${playmomentId}`} className="open-app-btn">
                                   {translate('VIEW IN APP')}
                                </a>
                            </div>
                            ) : null
                    }
                />
            </div>
            <div className="share-container">
                <ShareView
                    playmomentId={playmomentId}
                    openInApp={openInApp}
                    setPlaymomentLanguage={setPlaymomentLanguage}
                    translate={translate}
                />
            </div>
            <DefaultFooter hideContact />
        </div>
    )

}


export default ShareIndex