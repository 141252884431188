
import { useNavigate } from "react-router-dom";
import DefaultHeader from "../../components/DefaultHeader";
import DefaultFooter from "../../components/DefaultFooter";

import './style.scss'


const DeleteAccount = () => {

    const navigate = useNavigate();

    const onSelectLang = (language) => {
        try {
            //console.log('on select language', language)
            if (language === 'ro') {
                navigate('/ro/delete-account')
            } else {
                navigate('/en/delete-account')
            }
          
        } catch (error) {
            console.log('on error selecting language', error)
        }
    }

    return (
        <div className="Home">
            <div className="WhiteSection HomeSection">
                <DefaultHeader onSelectLang={onSelectLang} />
            </div>
            <div id="whyplay" className="Content delete-account-article">
                <h1>Delete One Kids account</h1>
                <div className="article-container">
                    <div className="TextSide overview">
                        <p data-outlined="false">You can make a request to delete your account from the app, It could take up to 48 hours to process the request.</p>

                        <p data-outlined="false">You can also email us at <strong>contact@onelearning.app</strong> to delete your account.</p>
                    </div>
                    <h2 className="subtitle">Steps to delete account from the app</h2>
                    <p></p>
                    <div className="delete-list">
                        <ol>
                        <li data-outlined="false">After loggin in tap on the hamburger menu on the top left.<br/>
                        </li>
                        <li data-outlined="false">Tap <strong>Delete account<span></span></strong>&nbsp; from the menu.<br/>
                        </li>
                        <li>In the delete account confirmation.
                            <ul>
                                <li data-outlined="false">Check <strong>I am sure</strong><br/>
                                </li>
                                <li data-outlined="false">Check <strong>Delete my data</strong> if you want to delete associated data</li>
                            </ul>
                        </li>
                        <li data-outlined="false">Press <strong>Delete account<span></span></strong>&nbsp; button to make delete account request.<br/>
                        </li>
                    </ol>
                    </div>
                </div>
            </div>
            <DefaultFooter />
        </div>
    )

}

export default DeleteAccount;