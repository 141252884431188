import React, { useEffect } from 'react';
import PolarSvg from '../../vectors/xmas/polar_hi.svg';
import './XcChallengeSteps.scss';

const XcChallengeSteps = (props) => {
    const { items } = props.data;

    return <div className="XcChallengeSteps">
        <ul className="XcChallengeSteps__list">
            {items.map((item, i) => <li className="XcChallengeStep"><img className="XcChallengeStep__bullet" src="/images/xmas/bullet.png" alt={`Step ${i+1}`} />{item.title}</li>)}
        </ul>
    </div>;
};

export default XcChallengeSteps;
