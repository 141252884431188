import React, { useState, useContext, useEffect } from 'react';
import ReactGA from "react-ga4";
import classNames from 'classnames';
import { validateEmail  } from './utils/Common';
import LangMenu from './components/LangMenu';
import Features from './views/Features';
import How from './views/How';
import { __ } from './i18n';
import RegionContext from './contexts/RegionContext';
import DefaultHeader from './components/DefaultHeader';
import DefaultFooter from './components/DefaultFooter';
import SkillsIcon from './vectors/belowbanner/futureskills.svg';
import DoingIcon from './vectors/belowbanner/curatedcontent.svg';
import ShowcaseIcon from './vectors/belowbanner/screenoff.svg';
import YouthClubsIcon from './vectors/youthclubs.svg';
import ParentsIcon from './vectors/parents.svg';
import EmployersIcon from './vectors/employers.svg';
import EarlyBrain from './vectors/home/earlybrain.svg';
import ImagePromo from './vectors/home/promo.svg';
import ImageInTheApp from './vectors/home/intheapp.svg';
import ImageNoPlay from './vectors/home/noplay.svg';
import ImageHappyChildhood from './vectors/home/happychildhood.svg';
import ImageOffscreenActivities from './vectors/home/offscreenactivities.svg';
import PolarSvg from './vectors/xmas/polar_hi.svg';
import Dialog from './components/Dialog';
import How1 from './vectors/how/1.svg';
import How2 from './vectors/how/2.svg';
import How3 from './vectors/how/3.svg';
import How4 from './vectors/how/4.svg';

import './Home.scss';

export const Enquire = (props) => {
  const [yourName, setYourName] = useState('');
  const [yourEmail, setYourEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [msgHead, setMsgHead] = useState("");
  const [msg, setMsg] = useState("");
  const [checked, setChecked] = useState(false);
  const { region, title, confirmText } = props;

  useEffect(() => {
    document.getElementById('thankYouFrame').onload = () => {
      setShowClose(true);
      ReactGA.event({category: 'contact', action: 'express-interest-success', label: yourName});
      setMsg(__('Welcome to the community - we cannot wait to play together. Thank you for sharing this journey with us and we will let you know when the app becomes available.'));
      setYourEmail("");
      setYourName("");
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!successMessage) {
      ReactGA.event({category: 'contact', action: 'subcribe-newsletter', label: yourName});
      let formData = new FormData();
      formData.append('FNAME', yourName);
      formData.append('EMAIL', yourEmail);

      if (!!yourName && !!yourEmail) {
        if (validateEmail(yourEmail)) {
          if (!checked) {
            setError(__('Tick the checkbox to confirm you are happy to receive communication from us.'));
            return false;
          }
          
          setOpen(true);
          setShowClose(false);
          setMsg(__("Joining the playground..."));
          document.getElementById('myform').submit();
          fetch('/php/mail.php', {
            method: 'POST',
            body: formData,
          });
          // fetch(region.subscribeURL, {
          //   method: 'POST',
          //   body: formData,
          // })
          // .then(response => {
          //   // if (response.redirected) {
          //   //   document.getElementById('thankYouFrame').src = response.url;
          //   // }
          // })
          // .then(result => {
          //   setShowClose(true);
          //   ReactGA.event({category: 'contact', action: 'express-interest-success', label: yourName});
          //   setMsg(__('Welcome to the community - we cannot wait to play together. Thank you for sharing this journey with us and we will let you know when the app becomes available.'));
          // })
          // .catch(error => {
          //   ReactGA.event({category: 'contact', action: 'express-interest-fail', label: yourName});
          //   setMsg(<>{__(`We couldn't register your interest. Please try again or send us an email at `)}<a href='mailto: contact@onelearning.app'>contact@onelearning.app</a></>);
          // });
        } else {
          setError(__('Please enter a valid email address.'));
        }
      } else {
        setError(__('Please enter both your email and first name.'));
      }  
    }
  }

  return <div id="enquire" className="Enquire GradientSection">
    <form id="myform" method='POST' action="https://app.mailingboss.com/lists/63e8c811b0b7f/subscribe"  target="ThankYouFrame">
      <h1>{ title !== undefined ? title :  __(`Subscribe to our newsletter!`)}</h1>
      <div className="EmailInput"><input name="EMAIL" type="email" value={yourEmail} onChange={(e) => setYourEmail(e.target.value)} autoCorrect="off" autoCapitalize="none" placeholder={__('Email')} /></div>
      <div className="FirstNameInput"><input name="FNAME" type="text" value={yourName} onChange={(e) => setYourName(e.target.value)} autoCorrect="off" autoCapitalize="none" placeholder={__('First Name')} /></div>
      <div className="gdprCheck form-control"><input type="checkbox" checked={checked} onChange={() => setChecked((v) => !v)} /><span>{__(`I agree to receiving information and offers from One Learning`)}</span></div>
      <em className={classNames({
          shown: !!successMessage || !!error,
          error: !!error,
        })}><span>{successMessage || error}</span></em>
      <div style={{ width: '1000px' }}></div>
      <div className="Controls">
        <button className={classNames({
          disabled: !!successMessage,
        })} type="submit" onClick={handleSubmit}>{confirmText || __(`Let's Play!`)}</button>
      </div>
    </form>
    <Dialog
      message=""
      onCancel={() => setOpen(false)}
      cancelLabel={__('Close')}
      open={open}
      className={'ThankYouDialog'}
      hideSubmit={true}
      hideClose={!showClose}
    >
      <div className={'ThankYouDialog__message'}>
        {showClose && <img alt={__('Thank you')} src={PolarSvg} />}{msg}
      </div>
    </Dialog>
</div>;
}

function Home(props) {
  const region = useContext(RegionContext);
  // useEffect(() => {
  //   global.iFrameResize({checkOrigin: false}, '#uservitals-widget-164bb673-2088-483d-baba-5f83665cf560');
  // }, []);

  return (
    <div className="Home">
      <div className="WhiteSection HomeSection">
        <DefaultHeader />
      </div>
      <div className="BannerContainer">
        <div className="Banner">
          <div class="Banner__slogan">
              <h1>{__('Create everlasting memories with your child. Anytime. Anywhere.')}</h1>
              <i></i>
              <b className="HashTag"><em className="HashTag__hash">#</em><em className="HashTag__label">{__(`15MinutesOfPlay`)}</em></b> 
              <b>{__(`Access over 100+ off-screen personalised, practical and playful activities that unlock children's essential skills for school and life.`)}</b>
          </div>
          {/* <div class="Banner__image">
            <img alt="Preview" src="/images/home/promo_en.png" />
          </div> */}
        </div>
      </div>
      <div className="WhiteSection HomeSection">
        <div class="Banner__footer">
            <ul>
              <li><img src={ShowcaseIcon} alt={__('Personalised off-screen activities')} /><b>{__('Personalised off-screen activities')}</b></li>
              <li><img src={DoingIcon} alt={__('Curated educational content')} /><b>{__('Curated educational content')}</b></li>
              <li><img src={SkillsIcon} alt={__('Develop skills for life')} /><b>{__('Develop skills for life')}</b></li>
            </ul>
        </div>
      </div>
      {/* <div id="testimonials" className="WhiteSection">
        <div className="Content">
          <iframe title="One Learning Testimonials" id='uservitals-widget-164bb673-2088-483d-baba-5f83665cf560'
          src='https://uservitals.co/embed/164bb673-2088-483d-baba-5f83665cf560'
          frameborder='0'
          scrolling='no' width='100%'></iframe>
        </div>
      </div> */}
      <div id="playlanguage" className="WhiteSection PlayLanguage">
        <img src="/images/home/playlanguage.jpg" alt={__('Language of play')} className="PlayLanguage__image" />
        <div className="PlayLanguage__content">
          <h1>{__(`Understand the unique way your child develops and learns`)}</h1>
          <ul>
            <li>
              <h2>{__(`Worried about your child's screen time?`)}</h2>
              <p>{__(`Not with us. All activities in our app are fun, practical, personalised and off-screen. The best way to create beautiful memories as a family.`)}</p>
            </li>
            <li>
              <h2>{__(`Bored of doing the same activities every day?`)}</h2>
              <p>{__(`Find activities based on your child's interests, available time and materials on hand, creating fun memories whilst learning together at home.`)}</p>
            </li>
            <li>
              <h2>{__(`Feeling like you're not getting enough time with your child?`)}</h2>
              <p>{__(`We are here for you! The app is aimed at busy parents that just want to connect and create happy and meaningful moments with their kids.`)}</p>
            </li>
            <li>
              <h2>{__(`Your child is going through changes and don't know what to do?`)}</h2>
              <p>{__(`Our educational content, parenting tips and resources are created by experts with over 20+ years of experience.`)}</p>
            </li>
            <li>
              <h2>{__(`Want to connect deeper with your child?`)}</h2>
              <p>{__(`The app is aimed at parents, grandparents, caregivers and teachers wishing to connect deeply and spend quality time with their children.`)}</p>
            </li>
            <li>
              <h2>{__(`Want to know how your child develops?`)}</h2>
              <p>{__(`Each activity will come with explanations beyond play, showing you what skills your child is developing and the impact of these skills will have later in life.`)}</p>
            </li>
          </ul>
        </div>
      </div>
      {region.code === 'ro' && <div id="featuredIn" className="WhiteSection">
        <div className="Content">
            <h1>Apariții Media</h1>
            <div className="FeaturedIn">
              <a rel="noreferrer" target="_blank" href="https://scoala9.ro/alt-scoala-one-learning-aplicatia-online-care-i-ajuta-pe-parinti-si/1448/">
                <img style={{ maxHeight: '40px' }} alt="Scoala 9" src="/images/featuredin/scoala9.png" />
              </a>
              <a rel="noreferrer" target="_blank" href="https://innovatorsforchildren.org/2022/09/06/innovators-for-children-one-learning/">
                <img style={{ maxHeight: '100px' }} alt="Innovators for Children" src="/images/featuredin/innovators_for_children.png" />
              </a>
              <a rel="noreferrer" target="_blank" href="https://sustenabilitate.biz/nejucamdesarbatori-parintii-si-copiii-sunt-invitati-sa-petreaca-timp-departe-de-ecrane/">
                <img style={{ maxHeight: '60px' }} alt="Sustenabilitate.biz" src="/images/featuredin/sustenabilitate.png" />
              </a>
              <a rel="noreferrer" target="_blank" href="https://www.revistabiz.ro/campania-nejucamdesarbatori-invita-parintii-si-copiii-sa-petreaca-timp-de-calitate-offline/">
                <img style={{ maxHeight: '30px' }} alt="Biz" src="/images/featuredin/biz.png" />
              </a>
              <a rel="noreferrer" target="_blank" href="https://start-up.ro/castigatorii-innovators-for-children-2022-20-000-euro-pentru-edutech-inovator/">
                <img style={{ maxHeight: '30px' }} alt="Start-up" src="/images/featuredin/startup.png" />
              </a>
              <a rel="noreferrer" target="_blank" href="http://www.romaniapozitiva.ro/csr/campania-nejucamdesarbatori-invita-parintii-si-copiii-sa-petreaca-timp-de-calitate-departe-de-ecrane/">
                <img style={{ maxHeight: '30px' }} alt="Romania Pozitiva" src="/images/featuredin/romania_pozitiva.png" />
              </a>
              <a rel="noreferrer" target="_blank" href="https://www.educatieprivata.ro/numarul-de-copii-care-sufera-de-anxietate-si-de-depresie-a-crescut-cu-30-in-doar-patru-ani-cum-ii-sprijna-one-learning-pe-cei-mici-sa-se-conecteze-cu-parintii-lor/">
                <img style={{ maxHeight: '50px' }} alt="Educatie Privata" src="/images/featuredin/educatie_privata.png" />
              </a>
              <a rel="noreferrer" target="_blank" href="https://www.edupedu.ro/activitati-gratuite-pentru-copii-jocuri-creative-si-de-imaginatie-povesti-scurte-si-accesibile-varstei-lor/">
                <img style={{ maxHeight: '60px' }} alt="Edupedu" src="/images/featuredin/edupedu.png" />
              </a>
            </div>
        </div>
      </div>}
      <How regionCode={region.code} />
      <div id="about" className={"WhiteSection"}>
        <div className="Content OurMission">
          <div className="SideImage">
            <div className="ImageSide">
              <img style={{ maxWidth: 450 }} src={EarlyBrain} alt={__('Employers')} />
            </div>
            <div className="TextSide">
              <h1>{__('What happens in our early childhood helps to create the foundations for our future learning, health, behaviour and happiness.')}</h1>
              <h2>{__('Why this matters')}</h2>
              <p>
              {__('Early childhood development is crucial for the long term success of our children.')}
              </p>
              <p>
              {__('Brains are built over time, from the bottom up. The basic architecture of the brain is constructed through an ongoing process that begins before birth and continues into adulthood. ')}
              </p>
              <p> 
              {__('Early childhood experiences affect the quality of that architecture by establishing either a sturdy or a fragile foundation for all of the learning, health and behavior that follow.')}
              </p>
              <div className="TextSide__links">
                <h4>{__('Research')}</h4>
                <ul>
                  <li><a target="_blank" rel="noreferrer" href="https://centreforearlychildhood.org/why-this-matters/"><img src="/images/home/research/royalfoundation.png" alt={__('UK Centre for Early Childhood')} /></a></li>
                  <li><a target="_blank" rel="noreferrer" href="https://developingchild.harvard.edu/science/key-concepts/brain-architecture/"><img src="/images/home/research/harvard.png" alt={__('Harvard Centre for the Developing Child ')} /></a></li>
                  <li><a target="_blank" rel="noreferrer" href="https://learningthroughplay.com/explore-the-research"><img src="/images/home/research/lego.png" alt={__('LEGO Foundation')} /></a></li>
                  <li><a target="_blank" rel="noreferrer" href="https://sdgs.un.org/goals/goal4 "><img src="/images/home/research/4qualityeducation.jpg" alt={__('UN SDG4')} /></a></li>
                  <li><a target="_blank" rel="noreferrer" href="https://www.unicef.org/press-releases/175-million-children-are-not-enrolled-pre-primary-education-unicef"><img src="/images/home/research/unicef.png" alt={__('UNICEF')} /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="how"  className="WhiteSection noPadding">
        <WhoHow />
      </div> */}
      <div id="partners" className="WhiteSection">
        <div className="Content">
            <h1>{__('Meet our playful partners')}</h1>
            <div className="SupportedBy">
              <a rel="noreferrer" target="_blank" href="https://bruntwood.co.uk/scitech/business-support/programmes/e4f/"><img alt="E4F" src="/images/supportedby/e4f.jpg" /></a>
              <a  rel="noreferrer"target="_blank" href="https://eyfoundation.com/"><img alt="EY Foundation" src="/images/supportedby/ey.jpg" /></a>
              <a  rel="noreferrer"target="_blank" href="https://startups.microsoft.com/"><img alt="Microsoft for Startups" src="/images/supportedby/msrocket.jpg" /></a>
              <a  rel="noreferrer"target="_blank" href="https://www.ashoka.org/en-gb/country/united-kingdom"><img alt="Ashoka Changemakers" src="/images/supportedby/ashoka.jpg" /></a>
              <a  rel="noreferrer"target="_blank" href="https://www.presencing.org/"><img alt="Presencing Institute" src="/images/supportedby/presencing.jpg" /></a>
              <a rel="noreferrer" target="_blank" href="https://innovatorsforchildren.org/editia-2022/"><img alt="Innovators for Children" src="/images/supportedby/innovatorsforchildren.jpg" /></a>
            </div>
        </div>
      </div>
      {/* <Enquire region={region} /> */}
      <DefaultFooter />
    </div>
  );
}



export default Home;