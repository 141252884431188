import { Row, Layout } from 'antd'
import Logo from '../../vectors/logomin.svg';
import '../../styles/antd.less';
import React from 'react';
const { Header } = Layout;


const HeaderComponent = () => {
    return (
        <React.Fragment>
             <Header style={{ backgroundColor: '#fff' }}>
            <img src={Logo} alt='logo image' height='38px'
                style={{ marginLeft: '5em', }}
            />
        </Header>
        </React.Fragment>
    )
}

export default HeaderComponent;