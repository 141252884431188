import React from 'react';
import { __ } from '../../i18n';
import DefaultHeader from '../../components/DefaultHeader';
import DefaultFooter from '../../components/DefaultFooter';
import '../../Home.scss';
import './style.scss';

const Page1 = (props) => {
    // const region = useContext(RegionContext);
    return <>
        <div className="Home AboutUs">
            <div className="WhiteSection HomeSection">
                <DefaultHeader />
            </div>
       <div className={"WhiteSection AboutUsTop"}>
        <div className="Content OurMission">
          <div className="SideImage">
            <div className="TextSide">
              <h1>About us</h1>
              <h2>Vision | why</h2>
              <p>We envision a world where all children have a happy childhood and a bright future regardless of their background. We want to create over 1m authentic moments of connection through play between parents and their children.</p>
              <h2>Mission | how do we do this</h2>
              <p>Our mission is to support the development of responsive, harmonious and nurturing relationships between young children and their parents or caregivers through access to quality educational resources.</p>
              <h2>Our values</h2>
              <p>Our team, partners and collaborators are found in the values ​​that guide our every action and decision: learning through experience, compassion, diversity, excellence and integrity.</p>
              <p></p><p></p>
            </div>
            <div className="ImageSide">
              <img width="400px" src="/images/aboutus/star_about.jpg" alt={__('Effects of no play')} />
            </div>
          </div>
        </div>
      </div>
      <div id="x11111" className={"GradientSection AboutUsImpact"}>
        <div className="Content OurMission">
          <div className="SideImage">
            <div className="TextSide">
              <h1>Impact</h1>
              <p>Our theory of change model aims to improve the connection between children and parents - focusing on the importance of cultivating relationships, communication and positive emotional bonds between parents and children.</p>
              <p>We provide the tools, knowledge and support to be a more confident parent and better understand the unique way their child learns and develops.</p>
              <h2>We do this using 3 main pillars:</h2>
              <h3>(1) Promotion of family welfare</h3><p>Building harmonious parent-child relationships to create a safe, growth-stimulating environment.</p>
              <h3>(2) Access to a parent support community</h3><p>Developmental and parenting strategies that enrich the learning environment and enhance development.</p>
              <h3> (3) Access to quality educational resources</h3><p>Resources to help create positive connections with materials found around the home and easy to incorporate into everyday life.</p>
              <h3>The long-term impact</h3><p>Developing children's essential life skills for school and life</p>
              <p></p>
            </div>
            <div className="ImageSide">
              <img width="400px" src="/images/aboutus/impact.png" alt={__('Children deserve a happy childhood')} />
              <a rel='noreferrer' href='https://ideas.developingchild.harvard.edu/' target='_blank' style={{ display: 'inline-block', padding: '10px 0 5px 0', borderRadius: '5px', overflow: 'hidden', backgroundColor: '#fff' }}>
                <img width="400px" src="/images/aboutus/toclogo.jpg" alt={__('Children deserve a happy childhood')} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={"AboutUsTeamSection WhiteSection"}>
        <div className="Content OurMission">
            <h1>The ONE Learning Team</h1>
            <h2>Play is the essence of life</h2>
           <p>Today's children will need a variety of skills to cope with an ever-changing and unpredictable world. We created this app to help children develop essential life skills anytime, anywhere through play.</p>

        </div>
        <ul className="AboutUsTeam">
          <li>
            <img className="AboutUsTeam__img" src="/images/aboutus/Azhar.jpg" alt={__('Azhar Xec')} />
            <span className="AboutUsTeam__name">Azhar Xec</span>
            <span className="AboutUsTeam__title">Engineering<br/>Cloud/Backend</span>
          </li>
          <li>
            <img className="AboutUsTeam__img" src="/images/aboutus/Ioana.jpg" alt={__('Ioana Axinte')} />
            <span className="AboutUsTeam__name">Ioana Axinte</span>
            <span className="AboutUsTeam__title">CEO</span>
          </li>
          <li>
            <img className="AboutUsTeam__img" src="/images/aboutus/steve.jpg" alt={__('Steve Fernandes')} />
            <span className="AboutUsTeam__name">Steve Fernandes</span>
            <span className="AboutUsTeam__title">Engineering<br/>UX/Frontend</span>
          </li>
          <li>
            <img className="AboutUsTeam__img" src="/images/aboutus/Ligia.jpg" alt={__('Ligia')} />
            <span className="AboutUsTeam__name">Ligia</span>
            <span className="AboutUsTeam__title">Digital Marketing</span>
          </li>
        </ul>
      </div>
      <div id="x11111" style={{ display: 'none' }} className={"GradientSection"}>
        <div className="Content OurMission">
              <h1>Consiliul Director</h1>
              <h2>{__('Play is the essence of life')}</h2>
              <p>
              {__(`Many experts describe play as a place - a place of magic and imagination, a place where a child can be fully one's self. Play is fun, but also meaningful and complex.`)}
              </p>
              <p>
              {__(`Some jobs will disappear, others will grow, and jobs that don’t even exist today will become the norm. Although we can’t predict with certainty the types of jobs that will exist in the future. `)}
              </p>
              <p> 
              {__(`Today’s children will need a variety of skills to thtive in an unpredictable world. The guides are here to help empower children to unlock their essential skills for life, anytime, anywhere through play.`)}
              </p>
        </div>
      </div>
      <DefaultFooter />
        </div>
    </>;
}

export default Page1;
