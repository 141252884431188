import { get } from 'lodash';

const commonConfig = {
    apiDateFormat: 'YYYY-MM-DD HH:mm:ss',
    //displayFormat: 'Do MMMM, YYYY',
    appTimeZone: 'Europe/London',
  };

const config = {
    development: {
        ...commonConfig,
        //baseUrl: 'http://localhost:3000/',
        //baseUrl: 'http://192.168.0.208:3000/',
        //baseUrl: 'https://oneapi.burrow.link/'
        baseUrl: 'https://laoexuci5b.execute-api.eu-central-1.amazonaws.com/production/',
    }, 
    staging: {
        ...commonConfig,
        baseUrl: 'https://oneapi.burrow.link/',
    },
    production: {
        ...commonConfig,
        baseUrl: 'https://laoexuci5b.execute-api.eu-central-1.amazonaws.com/production/',
    }
}

const environment = process.env.REACT_APP_ENVIRONMENT;

const appConfig = get(config, environment, config.development);


console.log('config environment', appConfig);


export default appConfig;

