import React, { useEffect, useState, createContext } from 'react';
import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import ReactGA from "react-ga4";
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/config/init';
import Home from './Home';
import Terms from './views/Terms';
import TermsRo from './views/TermsRo';
import Privacy from './views/Privacy';
import PrivacyRo from './views/PrivacyRo';
import { PartnersRo } from './screens/Partners';
import { ResearchRo } from './screens/Research';
import { CampaignRo } from './screens/Campaign';
import { Activities, ActivitiesRo } from './screens/Activities';
import { AboutUs, AboutUsRo } from './screens/AboutUs';
import { DownloadRedirectRo } from './screens/DownloadRedirect';
import { Challenge, ForgotPassword, ForgotPasswordOneKids, VerifiedSuccess, VerifyEmail, DeleteAccount } from './screens';
import XcRegister from './screens/XmasChallenge/XcRegister';
import XcChallenges from './screens/XmasChallenge/XcChallenges';
import XcChallengeDetails from './screens/XmasChallenge/XcChallengeDetails';
import RegionContext, { regions } from './contexts/RegionContext';
import Share from './screens/Share';
import './App.scss';
import { appRoutes } from './app/constants';

ReactGA.initialize('G-XZLDQFSP9X');

const queryClient = new QueryClient({ refetchOnWindowFocus: false });

const App = () => {
  // const [locationData, setLocationData] = useState({
  //   ip: "",
  //   countryName: "",
  //   countryCode: "",
  //   city: "",
  //   timezone: ""
  // });

  // const getGeoInfo = () => {
  //   axios
  //     .get("https://ipapi.co/json/")
  //     .then((response) => {
  //       let data = response.data;
  //       setLocationData({
  //         ip: data.ip,
  //         countryName: data.country_name,
  //         countryCode: data.country_calling_code,
  //         city: data.city,
  //         timezone: data.timezone
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const [ready, setReady] = useState(false);
  const [region, setRegion] = useState(regions['EN']);
  const [i18nInstance, setI18nInstance] = useState(null);

  // const handleChangeRegion = (regionCode) => {
  //   const r = regions[regionCode];
  //   console.log('CHANGING LAGUAGE', r);
  //   changeLanguage(r.language);
  //   setRegion(r);
  // };

  useEffect(() => {
    // i18n.changeLanguage("ro");
    if (window.location.href.indexOf('onelearning.ro/activitati') > -1) {
      window.location.href="https://onelearning.app/ro/activitati";
    } else if (window.location.host.indexOf('onelearning.ro') > -1) {
      window.location.href="https://onelearning.app/ro";
    }
    
    let r = regions['EN'];
    if (window.location.pathname.indexOf('/ro') > -1 || window.location.pathname.indexOf('/download') > -1 || window.location.pathname.indexOf('/promo') > -1) {
      r = regions['RO'];
    }
    setRegion(r);
    setI18nInstance(i18n({}, () => setReady(true), { lng: r.code }));

    // getGeoInfo();
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  });

  useEffect(() => {
    if (!ready) {
      return;
    }
    const scrollToId = window.location.href.split('#')[1];
    if (scrollToId) {
      setTimeout(() => {
        document.getElementById(scrollToId).scrollIntoView();
      }, 500);
    }
  }, [ready])

  if (window.location.href.indexOf('onelearning.ro/activitati') > -1) {
    return null;
  }
  
  return (
    <I18nextProvider i18n={i18nInstance}>
      {ready && <QueryClientProvider client={queryClient}>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <RegionContext.Provider value={region}>
            <div className="App">
              <Routes>
                <Route path="/:lang?" element={<Home />} />
                <Route path="/en/terms" element={<Terms />} />
                <Route path="/en/privacy" element={<Privacy />} />
                <Route path="/en/activities" element={<Activities />} />
                <Route path="/ro/activitati" element={<ActivitiesRo />} />
                <Route path="/en/aboutus" element={<AboutUs />} />
                <Route path="/ro/desprenoi" element={<AboutUsRo />} />
                <Route path="/ro/terms" element={<TermsRo />} />
                <Route path="/ro/privacy" element={<PrivacyRo />} />
                <Route path="/challenge/:challengeId" element={<Challenge />} />
                <Route path="/xmas/register" element={<XcRegister />} />
                <Route path="/xmas/inscriu" element={<XcRegister />} />
                <Route path="/activitati" element={<XcChallenges />} />
                <Route path="/activitati/:challengeId" element={<XcChallengeDetails />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/one-forgot-password" element={<ForgotPasswordOneKids />} />
                <Route path="verify-email" element={<VerifyEmail />} />
                <Route path="verified-success" element={<VerifiedSuccess />} />
                <Route path="/ro/delete-account" element={<DeleteAccount />} />
                <Route path="/en/delete-account" element={<DeleteAccount />} />
                <Route path="/ro/parteneri" element={<PartnersRo />} />
                <Route path="/ro/cercetare" element={<ResearchRo />} />
                <Route path="/ro/campanie" element={<CampaignRo />} />
                <Route path="/download" element={<DownloadRedirectRo />} />
                <Route path="/promo" element={<DownloadRedirectRo />} />
                <Route path={`/${appRoutes.share}`} element={<Share openInApp/>} />
                <Route path={`/${appRoutes.share}/:id`} element={<Share openInApp/>} />
                <Route path={`/${appRoutes.onekidsapp}/challengeDetails`} element={<Share />} />
                <Route path={`/${appRoutes.onekidsapp}/challengeDetails/:id`} element={<Share/>} />
                <Route path="*" element={<Home />} />
              </Routes>
            </div>
          </RegionContext.Provider>
        </QueryParamProvider>
    </QueryClientProvider>}
    </I18nextProvider>
  );
}

export default App;
