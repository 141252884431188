import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import { useCountdown } from './useCountdown';
import StarPortalSvg from '../../vectors/xmas/starportal1.svg';
import './XcCountdown.scss';

const XcCountdown = (props) => {
    let dt = new Date(1669870800000);
    if (Date.now() > 1669870800000) {
        dt = new Date(1671944400000);
    }
    
    const [days, hours, minutes, seconds] = useCountdown(dt);

    const lpadAndSplit = (v) => {
        const s = String(v).padStart(2, '0');
        return <><span>{s[0]}</span><span>{s[1]}</span></>;
    }

    return <div className="XcCountdown">
        <div className="XcCountdownContent">
            <div className="XcCountdownGraphic">
                {/* <Lottie 
                    options={{
                    loop: true,
                    autoplay: true,
                    animationData: ChristmasTreeAni,
                    rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice"
                    }
                    }}
                    width={300}
                    height={370}
                /> */}
                {/* <div className="XcCountdown__PolarContainer">
                    <img className="XcCountdown__Polar" src={PolarSvg} alt="Hi" />
                </div> */}
                <div className="XcCountdown__StarPortalContainer"><img className="XcCountdown__StarPortal" src={StarPortalSvg} alt="Hi" /></div>
                
            </div>
            <div className="XcCountdownMessage">
                <div style={{ visibility: Date.now() < 1671944400000 ? 'visible' : 'hidden' }} className="XcCountdownTimer">
                    <div className="XcCountdownTimerUnit">
                        <div className="XcCountdownTimerUnit__value">{lpadAndSplit(days)}</div>
                        <div className="XcCountdownTimerUnit__label">ZILE</div>
                    </div>
                    <div className="XcCountdownTimerUnit">
                        <div className="XcCountdownTimerUnit__value">{lpadAndSplit(hours)}</div>
                        <div className="XcCountdownTimerUnit__label">ORE</div>
                    </div>
                    <div className="XcCountdownTimerUnit">
                        <div className="XcCountdownTimerUnit__value">{lpadAndSplit(minutes)}</div>
                        <div className="XcCountdownTimerUnit__label">MINUTE</div>
                    </div>
                    <div className="XcCountdownTimerUnit">
                        <div className="XcCountdownTimerUnit__value">{lpadAndSplit(seconds)}</div>
                        <div className="XcCountdownTimerUnit__label">SECUNDE</div>
                    </div>
                </div>
                <h1>Intră în lumea magică a ursulețului polar Star, unde povestea prinde viață prin activități si jocuri simple care pot fi făcute oricând, oriunde.<br/><br/>Timpul nu poate fi dat înapoi, dar puteți crea amintiri frumoase împreună care rămân toată viața.<br/><button onClick={props.onShowGuide}>Cum ne jucăm?</button></h1>
            </div>
        </div>
    </div>;
}

export default XcCountdown;
