import React, { useState, useEffect } from 'react';
import { __ } from '../../i18n';
import DefaultHeader from '../../components/DefaultHeader';
import DefaultFooter from '../../components/DefaultFooter';
import { Enquire } from '../../Home';
import '../../Home.scss';
import './style.scss';

const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
      return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
  }

  return "unknown";
}

const Page1 = (props) => {

    useEffect(() => {
      if (getMobileOperatingSystem() === 'Android') {
        window.location.href = "https://play.google.com/store/apps/details?id=app.onelearning.onekids";
      } else if (getMobileOperatingSystem() === 'iOS') {
        window.location.href = "https://apps.apple.com/us/app/one-learning-kids/id6443809900";
      }
    }, []); 

    return <>
        <div className="Home DownloadRedirect">
            <div className="WhiteSection HomeSection">
                <DefaultHeader />
            </div>
            <div id="whyplay" className={"WhiteSection"}>
              <div className="Content OurMission">
                <div className="SideImage">
                  <div className="ImageSide">
                    <img width="300px" src="/images/download/discovery.png" alt={__('Preview')} />
                  </div>
                  <div className="TextSide">
                    <h1>Descărcați aplicația <i>ONE Learning Kids</i></h1>
                    <p>Începeți să creați amintiri frumoase în familie cu peste 100 de jocuri practice și personalizate bazate pe activități în afara ecranului! 
                    </p>
                    <p>Disponibil pe Android - Google Play și iOS - Apple Store.</p>
                    {/* <p className="DownloadControls">
                      <a href="https://play.google.com/store/apps/details?id=app.onelearning.onekids"><img width="150px" src="/images/download/playstore.png" alt="Playstore" /></a>
                      <a href="https://apps.apple.com/us/app/one-learning-kids/id6443809900"><img width="150px" src="/images/download/appstore.png?r=100" alt="App store" /></a>
                    </p>                     */}
                  </div>
                </div>
              </div>
            </div>
            <DefaultFooter />
        </div>
    </>;
}

export default Page1;
