import { isEmpty } from "lodash";
import { useMutation, useQuery } from "react-query";
import { appLinks, queryKeys } from "../../app/constants";
import { httpPostAsync } from "../../utils/httpHelpers";

function useSetPassword(passwordResetToken) {


    //#region  forgot password details
    const getDetailsAsync = async () => {
        try {
            
            const response = await httpPostAsync(appLinks.oneForgotPasswordDetails, {  ref: passwordResetToken });

            console.log('forgot password details', response);

            return response;

        } catch (error) {
            console.log('error getting forgot password details', error);
        }
    }

    const {
        data: forgetPasswordDetails = {},
        isLoading: isFetching,
        // isSuccess,
        // refetch: refetchChallengeDetailsAsync,
    } = useQuery(
        [queryKeys.oneForgotPasswordDetails, passwordResetToken],
        getDetailsAsync,
        {
            enabled: !isEmpty(passwordResetToken),
            refetchOnWindowFocus: false
        }
    );

    //#endregion

    //#region set forgot password
    const setPasswordAsync = async (params) => {
        try {

            console.log('env variables', process.env);

            const response = await httpPostAsync(appLinks.oneKidsSetPassword, params);

            console.log('set forgot password response', response);

            return response;

        } catch (error) {
            console.log('error setting password: forgot password', error);
        }
    }

    const mutationQueryKey = [queryKeys.oneSetPassword, passwordResetToken]

    const {
        data: setPaswordResponse,
        isLoading: isUpdating,
        mutateAsync: onSetPasswordAsync,
    } = useMutation(
        setPasswordAsync,
        { mutationKey: mutationQueryKey }
    );

    //#endregion

    return {
        forgetPasswordDetails,
        isFetching,
        isUpdating,
        onSetPasswordAsync,
        setPaswordResponse,
    }

}


export default useSetPassword;