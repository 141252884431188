import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../utils/Translation';
import classNames from 'classnames';
import ModalPortal from '../ModalPortal';
import './style.scss';

const Dialog = (props) => {
  const {
    onSubmit,
    onCancel,
    message,
    submitLabel,
    cancelLabel,
    open,
    className,
    hideSubmit,
    hideClose,
  } = props;

  const handleSubmit = (ev) => {
    ev.preventDefault();
    onSubmit();
  };

  const handleCancel = (ev) => {
    ev.preventDefault();
    onCancel();
  };

  return (
    <ModalPortal>
      {open && (
        <div
          onKeyDown={(ev) => {
            ev.stopPropagation();
          }}
          className="Overlay"
        >
          <form
            onSubmit={handleSubmit}
            className={classNames('Dialog', className)}
          >
            <h3>{message}</h3>
            {props.children}
            <div className="Dialog__actions">
              {!hideSubmit && <button type="submit" onClick={handleSubmit}>
                {submitLabel}
              </button>}
              {!hideClose && <button onClick={handleCancel}>{cancelLabel}</button>}
            </div>
          </form>
        </div>
      )}
    </ModalPortal>
  );
};

Dialog.defaultProps = {
  submitLabel: t('Ok'),
  cancelLabel: t('Cancel'),
  onSubmit: () => {},
  onCancel: () => {},
};

Dialog.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  submitLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  hideSubmit: PropTypes.bool,
  hideClose: PropTypes.bool,
};

export default Dialog;
