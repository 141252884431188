import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import XcBackSvg from '../../vectors/xmas/xcback.svg';
import DoubleDownSvg from '../../vectors/xmas/doubledown.svg';
import FlyingSantaSvg from '../../vectors/xmas/flyingsanta.svg';
import XcChallengeFooter from './XcChallengeFooter';
import XcChallengeSummary from './XcChallengeSummary';
import XcChallengeSteps from './XcChallengeSteps';
import XcChallengeResources from './XcChallengeResources';
import { useChallengeDetails } from "../Challenge/useChallengeDetail";
// import { challengeList } from './XcChallenges';
import './XcChallengeDetails.scss';

const XcChallengeDetails = (props) => {
    const { challengeId } = useParams();
    const navigate = useNavigate();
    const [challengeList, setChallengeList] = useState([]);
    useEffect(() => {
        if (!challengeId || challengeId <= 0) {
            navigate('/activitati', { replace: true });
        }
    }, [challengeId]);

    const { isLoading, data = {} } = useChallengeDetails(challengeId);
    useEffect(() => {
        document.body.style.background = `url('/images/xmas/bg2.jpg')`;
        window.fetch(`/images/xmas/data.json?rand=${Math.round(Math.random()*1000000)}`)
        .then((response) => response.json())
        .then(list => {
            if (list) {
                setChallengeList(list);
            }
        });
        return () => {
            document.body.style.background = '#fff';
        };
    }, []);

    const adventDay = challengeList.findIndex(c => c.id === parseInt(challengeId)) + 1;
    const hasResources = data.resources && data.resources.length;

    return isLoading ? <></> : <div className="XcChallengeDetails">
        {/* <img className="XCFlyingSanta" alt="Fly santa" src={FlyingSantaSvg} /> */}
        <XcChallengeFooter />
        <div className="XcChallengeDetailsHeader">
            <button onClick={() => navigate('/activitati')} className="XcBackButton"><img className="XcBackButton__icon" src={XcBackSvg} width={33} alt="Back" /><span className="XcBackButton__label">{adventDay ? `Day ${adventDay}` : ''}</span></button>
        </div>
        <div className="XcChallengeContent">
            <div className="XcChallengeSummaryContainer">
            <button id="summary" onClick={() => window.location.href='#summary'} className="XcNextSectionButton"><img className="XcNextSection__icon" src={DoubleDownSvg} width={27} alt="Next" /><span className="XcNextSection__label">Activitate</span></button>
                <XcChallengeSummary data={data} />
            </div>
            <div className="XcChallengeStepsContainer">
                <button id="steps" onClick={() => window.location.href='#steps'} className="XcNextSectionButton"><img className="XcNextSection__icon" src={DoubleDownSvg} width={27} alt="Next" /><span className="XcNextSection__label">Pași</span></button>
                <XcChallengeSteps data={data.sections ? data.sections.find((r) => r.type === 'steps') : []} />
            </div>
        </div>
        {hasResources && <div className="XcChallengeResourcesContainer">
            <button id="resources" onClick={() => window.location.href='#resources'} className="XcNextSectionButton"><img className="XcNextSection__icon" src={DoubleDownSvg} width={27} alt="Next" /><span className="XcNextSection__label">Resurse</span></button>
            <XcChallengeResources data={data.resources} />
        </div>}
    </div>;
};

export default XcChallengeDetails;
