import React from "react";
import { Card, Row, Col, Button, Layout, Image } from "antd";
import { useQueryParam, StringParam } from 'use-query-params';
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../../components/Header";
import useVerifyEmail from "./useVerifyEmail";

import '../../styles/antd.less';

const { Content } = Layout;

export default function VerifyEmail() {

    const navigate = useNavigate();

    const [token] = useQueryParam('token', StringParam);

    const {
        confirmEmailAsync,
        confirmEmailResponse,
        isConfirming
    } = useVerifyEmail(token)

    const onConfirmEmailAsync = async () => {
        try {

            const response = await confirmEmailAsync()

            if (response?.code === '0' || response?.code === '0.1.1') {

                const name = response?.data?.user;
                const email = response?.data?.email;

                navigate('/verified-success', { state: { name, email } })

            }

        } catch (error) {
            console.log('error confirming email ', error)
        }
    }

    return (
        <div className="antdContainer">
            <Layout>
                <HeaderComponent />
                <Content style={{ height: '100vh', backgroundColor: 'rgb(200 221 254)', paddingTop: '4em' }}>
                    <Row justify="center">
                        <Col span={8}>
                            <Card>
                                <Row justify="center" style={{ paddingBottom: '.9em' }}>
                                    <Image preview={false} src="images/email-verify.svg" height={180} />
                                </Row>
                                <Row justify="center" >
                                    <h1 style={{ paddingTop: 12, fontSize: 20 }}>Verify your email address !</h1>
                                </Row>
                                <Row justify="center" style={{ paddingTop: 8, paddingBottom: 8 }}>
                                    <p style={{ textAlign: 'center' }}>Verify your email address by clicking button below. </p>
                                </Row>
                                <Row justify="center">
                                    <Button
                                        loading={isConfirming}
                                        onClick={onConfirmEmailAsync}>
                                        Confirm Email
                                    </Button>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </div>
    )

}


