import React, { useState, useEffect, useCallback } from 'react';
import XcChallengeResourceWindow from './XcChallengeResourceWindow';
import PlayButtonSvg from '../../vectors/xmas/playbutton.svg';
import './XcChallengeResources.scss';

const XcChallengeResource = (props) => {
    const { data } = props;
    const [resourceShown, setResourceShown] = useState(false);
    const [currentResource, setCurrentResource] = useState(null);

    const hasData = data && data.length;

    const openResourceWindow = (r) => {
        setCurrentResource(r);
        setResourceShown(true);
        document.body.style.overflow = 'hidden';
    };

    const closeResourceWindow = () => {
        setResourceShown(false);
        document.body.style.overflow = 'visible';
    }

    const nextResource =  useCallback(() => {
        const dataIndx = data.findIndex(r => r.id === currentResource.id);
        if (data[dataIndx+1]) {
            setCurrentResource(data[dataIndx+1]);
        } else {
            setCurrentResource(data[0]);
        }
    }, [currentResource, data]);

    const prevResource = useCallback(() => {
        const dataIndx = data.findIndex(r => r.id === currentResource.id);
        if (data[dataIndx-1]) {
            setCurrentResource(data[dataIndx-1]);
        } else {
            setCurrentResource(data[data.length-1]);
        }
    }, [currentResource, data]);

    const handleKeyPress = useCallback((event) => {
        if (event.key === 'ArrowLeft') {
            prevResource();
        }
        if (event.key === 'ArrowRight') {
            nextResource();
        }
      }, [nextResource, prevResource]);
    
      useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
          document.removeEventListener('keydown', handleKeyPress);
        };
      }, [handleKeyPress]);

    const showCurrentResourceWindow = currentResource && resourceShown;

    return hasData ? <>
        <div className="XcChallengeResources">
            <div className="XcChallengeResources__list">
                {data.map(r => <div onClick={() => openResourceWindow(r)} className="XcChallengeResourceItem">
                    {r.type === 'youtube_video' ? 
                        <i><img alt="Video" src={PlayButtonSvg} /></i> : 
                        <span style={{ backgroundImage: `url('${r.downloadLink}')` }} className="XcChallengeResourceItem__image">&nbsp;</span>
                    }
                    <span className="XcChallengeResourceItem__label">{r.name}</span>
                </div>)}
            </div>
        </div>
        {showCurrentResourceWindow && <XcChallengeResourceWindow 
            onClose={closeResourceWindow}
            onNext={nextResource}
            onPrev={prevResource}
            data={currentResource}
        />}
    </> : <></>;
};

export default XcChallengeResource;
