import { useMediaQuery } from "react-responsive";



export function useIsDesktopOrLaptop() {

    const isDesktopOrLaptop = useMediaQuery({

        query: '(min-width: 992px)'

    });

    return { isDesktopOrLaptop } 
}

export function useIsDesktopLarge() {

    const isDesktopLarge = useMediaQuery({

        query: '(min-width: 1400px)'

    });
    return { isDesktopLarge }

}


export function useIsTabletOrMobile() {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });

    return { isTabletOrMobile }

}

export function useVerySmall() {

    const isVerySmall = useMediaQuery({

        query: '(min-width: 450px)'

    });

    return { isVerySmall } 
}