import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import XcChallengeFooter from './XcChallengeFooter';
import XcCountdown from './XcCountdown';
import XcGuideWindow from './XcGuideWindow';
import XcAppPromo from './XcAppPromo';
import FlyingSantaSvg from '../../vectors/xmas/flyingsanta.svg';
import LogoSvg from '../../vectors/xmas/onelearningcircle.svg';
import classNames from 'classnames';
import adventCards from '../../vectors/xmas/adventcards';
import pealed from '../../vectors/xmas/adventcards/pealed.svg';
import './xcChallenges.scss';

function getScrollbarWidth() {

    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);
  
    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);
  
    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
  
    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);
  
    return scrollbarWidth;
  
  }

const XcChallengeCard = (props) => {
    const { id, indx, glow, opened, locked } = props.challengeData;

    return <div onClick={() => {
        if (id > 0 && !locked) {
            props.navigate(`/activitati/${id}`);
        }
    }} className={classNames({
        'XcChallengeCard': true,
        'XcChallengeCard--even': indx % 2 > 0,
        'XcChallengeCard--glow': glow,
    })}>
        {opened ? <>
            <img className="XcChallengeCard__image" src={`/images/xmas/thumbs/${id}.png`} height="145" alt={`Day ${indx+1}`} />
            {/* <div className="XcChallengeCard__label">60 Secunde - 1 Minut</div> */}
            <img className="XcChallengeCard__peel" src={pealed} height="145" alt={`Day ${indx+1}`} />
        </> : <img src={`/images/xmas/advent${indx+1}.png`} width="60%" alt={`Day ${indx+1}`} />}
    </div>;
}

const XcChallenges = (props) => {
    const navigate = useNavigate();
    const [copied, setCopied] = useState(false);
    const timeout = useRef(null);
    const [challengeList, setChallengeList] = useState([]);
    const [showGuide, setShowGuide] = useState(false);
    const [scrollbarWidth, setScrollbarWidth] = useState('15px');

    useEffect(() => {
        document.body.style.background = `url('/images/xmas/bg2.jpg')`;
        window.fetch(`/images/xmas/data.json?rand=${Math.round(Math.random()*1000000)}`)
        .then((response) => response.json())
        .then(list => {
            if (list) {
                setChallengeList(list);
            }
        });
        setScrollbarWidth(getScrollbarWidth());
        return () => {
            document.body.style.background = '#fff';
            clearTimeout(timeout.current);
        };
    }, []);

    const copy = () => {
        setCopied(true);
        timeout.current = setTimeout(() => {
            setCopied(false);
        }, 3000);
        navigator.clipboard.writeText("#15MINUTEDEJOACA");
    };

    const openGuide = () => {
        setShowGuide(true);
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = scrollbarWidth;
    };

    const closeGuide = () => {
        setShowGuide(false);
        document.body.style.overflow = 'visible';
        document.body.style.paddingRight = '0';
    };

    return <div className="XcChallenges">
        {/* <img className="XCFlyingSanta" alt="Fly santa" src={FlyingSantaSvg} /> */}
        <XcChallengeFooter />
        <div className="XCForeground">
            <div className="XCHeader">
                <div className="XCLogoContainer">
                    <img className="XCLogo" src={LogoSvg} alt="One Learning" />
                </div>
                <div className="XCHeaderContent">
                    <div className="XcHashTag" onClick={copy}>
                        <img alt="Hash" src="/images/xmas/hash1.svg" />
                        <span>15MINUTEDEJOACA</span>
                        <b>{copied ? 'Copied!' : 'Copy'}</b>
                    </div>
                </div>
            </div>
            <div className="XcHashTagContainer">
                <div className="XcHashTag" onClick={copy}>
                    <img alt="Hash" src="/images/xmas/hash1.svg" />
                    <span>15MINUTEDEJOACA</span>
                    <b>{copied ? 'Copied!' : 'Copy'}</b>
                </div>
            </div>
            <XcCountdown onShowGuide={openGuide} />
            <div className="XcChallengeListContainer">
                <ul className="XcChallengeList">
                    {challengeList.map((c, i) => {
                        return <li><XcChallengeCard navigate={navigate} challengeData={{indx: i, ...c}} /></li>;
                    })}
                </ul>
            </div>
            <XcAppPromo />
        </div>
        <div className="XcFooter">&nbsp;</div>
        {showGuide && <XcGuideWindow onClose={closeGuide} />}
    </div>;
};

export default XcChallenges;
