import React from 'react';
import i18n from 'i18next';

const escapedNewLineToLineBreakTag = (s) => {
  return s && s.split ? s.split('\n').map((item, index) => {
      return (index === 0) ? item : [<br key={index} />, item]
  }) : s;
};

export const __ = (key, opts) => {
  if (process.env.NODE_ENV !== 'production' && !i18n.isInitialized) {
    console.warn(
      'Warning: the translate function (`t`) has been called before translations have been loaded. Please ensure that translation is performed during the React lifecycle of your application.'
    );
  }
  const translation = i18n.t(key, opts);
  if (translation === undefined) {
    return key;
  }
  return escapedNewLineToLineBreakTag(translation);
};

export const changeLanguage = (lang) => {
  i18n.changeLanguage(lang);
};

export const getCurrentLanguage = () => i18n.language
