import React from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';



export function ForgotPasswordForm({ resetPasswordAsync, onFinishFailed }) {

    const [form] = Form.useForm();

    const navigate = useNavigate();

    const goHome = () => {
        try {

            navigate(`/`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }

    const passwordDontMatchValidator = (rule, value, callback) => {
        try {

            const { rePassword } = form.getFieldsValue();

            if (value !== rePassword) {
                return callback('passwords dont match')
            }

            return callback();

        } catch (error) {
            console.log('error validating password', error)
            //callback('Could not validate duration')
        }
    }


    const repasswordDontMatchValidator = (rule, value, callback) => {
        try {

            const { password } = form.getFieldsValue();

            if (value !== password) {
                return callback('passwords dont match')
            }

            return callback();

        } catch (error) {
            console.log('error validating password', error)
            //callback('Could not validate duration')
        }
    }

    return (
        <Form
            name="resetPassword"
            onFinish={resetPasswordAsync}
            onFinishFailed={onFinishFailed}
            form={form}
            autoComplete="off"
        >
            <Row justify="center">
                <Col span={12}>
                    <Form.Item
                        name="password"
                        rules={[
                            { required: true, message: 'Please enter password!' },
                            { min: 5, message: 'Please choose a password of minimum length 5' },
                            { validator: passwordDontMatchValidator, validateTrigger: ['onValueChange'] }
                        ]}
                        style={{ width: '100%' }}
                    >
                        <Input.Password
                            placeholder="New password"
                        //disabled={isUserInvalid || isActivationSuccess}
                        // className={classes.Input}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="center">
                <Col span={12}>
                    <Form.Item
                        name="rePassword"
                        rules={[
                            { required: true, message: 'Please enter password!' },
                            { validator: repasswordDontMatchValidator, validateTrigger: ['onValueChange'] }
                        ]}
                    >
                        <Input.Password
                            placeholder="Re-enter Password"
                        //className={classes.Input}
                        // disabled={isUserInvalid || isActivationSuccess}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="center">
                <Form.Item>
                    <Button
                        type="primary"
                        shape="round"
                        htmlType="submit"
                    // className={classes.btn}
                    // disabled={isUserInvalid || isActivationSuccess}
                    > Reset password
                    </Button>
                </Form.Item>
            </Row>
            <Row justify='center' style={{ padding: '1em', paddingBottom: '2em' }}>
                <Button onClick={goHome} type="link" icon={<ArrowLeftOutlined />}  >go to Home</Button>
            </Row>
        </Form>
    )

} 