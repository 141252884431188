import React, { useEffect, useMemo, useRef, useState } from 'react';
import YouTube from "react-youtube";
import { useIsDesktopOrLaptop } from "../Challenge/hooks/media";
import { getYoutubeVideoId } from "../../utils/Common";
import XcBackBlackSvg from '../../vectors/xmas/xcbackBlack.svg';
import ArrowLeftSvg from '../../vectors/xmas/arrowleft.svg';
import ArrowRightSvg from '../../vectors/xmas/arrowright.svg';
import './XcChallengeResourceWindow.scss';

const YoutubeResource = (props) => {

    const { link, name } = props;

    //console.log('sectoin step', props)
    const videoId = useMemo(() => getYoutubeVideoId(link), [link]);

    const [width, setWidth] = useState(100);
    const [height, setHeight] = useState(100);

    const containerRef = useRef();

    useEffect(() => {

        let containerWidth = window.innerWidth;
       // console.log('youtube width', containerWidth);
        containerWidth =  containerWidth >= 1200 ? 1200 : containerWidth;
        // h/w = 16/9 = h1/w1
        // w1/h1 = 9/16 * h1
        setWidth(containerWidth);
        setHeight((9/16) * containerWidth)

    }, [])

    const { isDesktopOrLaptop } = useIsDesktopOrLaptop();

    return (
        <div className={` ${isDesktopOrLaptop ? 'youtubeDesktop' : 'youtube'}`} ref={containerRef}>
            <YouTube className="youtubeFrame" videoId={videoId} opts={{  width, height, }} />
        </div>
    )

}

const useSwipe = (prev, next) => {
    const [touchStart, setTouchStart] = React.useState(0);
    const [touchEnd, setTouchEnd] = React.useState(0);

    function handleTouchStart(e) {
        setTouchStart(e.targetTouches[0].clientX);
    }

    function handleTouchMove(e) {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd() {
        if (touchStart - touchEnd > 50) {
            next();
        }

        if (touchStart - touchEnd < -50) {
            prev();
        }
    }

    return {
        handleTouchStart,
        handleTouchEnd,
        handleTouchMove,
    };
};

const XcChallengeResourceWindow = (props) => {
    const {data, onClose, onNext, onPrev} = props;
    
    const {
        handleTouchStart,
        handleTouchEnd,
        handleTouchMove,
    } = useSwipe(onPrev, onNext);

    return <div 
            onTouchStart={e => handleTouchStart(e)}
            onTouchMove={e => handleTouchMove(e)}
            onTouchEnd={() => handleTouchEnd()}
            className="XcChallengeResourceWindow"
        >
        <div className="XcChallengeResourceWindowHeader">
            <button onClick={onClose} className="XcChallengeResourceWindowHeader__closeButton"><img className="XcBackButton__icon" src={XcBackBlackSvg} width={33} alt="Back" /></button>
            <div className="XcChallengeResourceWindowHeader__title"><span>{data.name}</span></div>
        </div>
        <div className="XcChallengeResourceControls">
            <button onClick={onPrev} className='XcChallengeResourceControls__prevButton'><img alt="Prev" src={ArrowLeftSvg} /></button>
            <button onClick={onNext} className='XcChallengeResourceControls__nextButton'><img alt="Next" src={ArrowRightSvg} /></button>
        </div>
        <div className="XcChallengeResourceFrame">
            {data.type === 'youtube_video' ?
                <YoutubeResource link={data.link} name={data.name} /> : <img width="100%" alt="Resource" src={data.downloadLink} />}
        </div>
    </div>;
}

export default XcChallengeResourceWindow;
