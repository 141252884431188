import { useQueryParam, StringParam } from 'use-query-params';
import { useIsDesktopLarge } from "../Challenge/hooks/media";
import useSetPassword from './useSetPassword';
import '../../styles/antd.less';
import './style.scss';
import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import ForgotPasswordContainer from "./Container";
import Empty from "../../components/Empty";
import { 
     DefaultDescription,
     InvalidRequest, 
     PasswordSet,
     PreviousPassword,
     ActionFailed, 
     InvalidRequestTimedOut, 
     RequestProcessed, 
     UserDeleted,
     UserNotActive,
     NoOrganization,
     NoUserEmail,
     UnknownException,
     EmptyPassword,
     password5Char
    } from "./description";
import { ForgotPasswordForm } from "./form/default";
import { Footer } from '../ForgotPassword/footer';



const forgotPasswordStates = {
    invalidRequest: 'invalidRequest',
    validRequest: 'validRequest',
    previousPassword: 'previousPassword',
    actionFailed: 'actionFailed',
    actionSuccess: 'actionSuccess',
}

const descriptionComponentConfig = {
    [forgotPasswordStates.invalidRequest]: InvalidRequest,
    [forgotPasswordStates.validRequest]: DefaultDescription,
    [forgotPasswordStates.previousPassword]: PreviousPassword,
    [forgotPasswordStates.actionFailed]: ActionFailed,
    [forgotPasswordStates.actionSuccess]: PasswordSet,
}

const formComponentConfig = {
    [forgotPasswordStates.invalidRequest]: Empty,
    [forgotPasswordStates.validRequest]: ForgotPasswordForm,
    [forgotPasswordStates.previousPassword]: ForgotPasswordForm,
    [forgotPasswordStates.actionFailed]: Empty,
    [forgotPasswordStates.actionSuccess]: Empty,
}

const footerComponentConfig = {
    [forgotPasswordStates.invalidRequest]: Empty,
    [forgotPasswordStates.validRequest]: Footer,
    [forgotPasswordStates.previousPassword]: Footer,
    [forgotPasswordStates.actionFailed]: Empty,
    [forgotPasswordStates.actionSuccess]: Empty,
}

function getState(forgetPasswordDetails, setPaswordResponse) {
    try {

        //TODO 
        // when getting password details
        // 400.8.14 => user not active
        // 400.8.11 Forgot password request is expired or invalid, please make a new request
        // 400.8.12 forgot password request processed or expired
        // 400.8.8 user not active in any organisation

        // when setting password 
        // 400.8.15 password is empty
        // 400.8.16 password is less then 5 characters
        // 400.8.11
        // 400.8.12
        // 400.8.13 Invalid forgot password request
        // 400.8.14
        // 400.8.8
        // 400.8.17
        // 500.8.3

        if (setPaswordResponse && setPaswordResponse.code === '400.1.17') {
            return forgotPasswordStates.previousPassword;
        } else if (setPaswordResponse && setPaswordResponse.code != '0') {
            return forgotPasswordStates.actionFailed;
        } else if (setPaswordResponse && setPaswordResponse.code == '0') {
            return forgotPasswordStates.actionSuccess;
        } else if (!forgetPasswordDetails || forgetPasswordDetails.code) {
            return forgotPasswordStates.invalidRequest;
        } else if (forgetPasswordDetails && !forgetPasswordDetails.code) {
            return forgotPasswordStates.validRequest;
        }

    } catch (error) {
        console.log(`error in ${getState.name}`, error)
        return ''
    }
}
export default () => {

    const [desktopscreen, setDesktopScreen] = useState(false);
    const { isDesktopLarge } = useIsDesktopLarge();

    useEffect(() => {

        setDesktopScreen(isDesktopLarge)


    }, [isDesktopLarge])

   // console.log('screen is desktop or not ', desktopscreen);

    const [token] = useQueryParam('token', StringParam);

    // useEffect(() => {

    //   console.log('one resete password token', token);

    // }, [token])

    const {
        forgetPasswordDetails = {},
        isFetching,
        isUpdating,
        onSetPasswordAsync,
        setPaswordResponse
    } = useSetPassword(token)

    // conditions
    // error getting forgot password details, if there are is an code, then show error
    // error when resetting password
    // if forgetPasswordDetails.code show error and no reset password form
    // if response.code !== 0, when setting password, different screen
    // nor just show forward password success

    const resetPasswordAsync = async (values) => {
        try {
            console.log('values', values);

            const { password } = values;

            await onSetPasswordAsync({ ref: token, password });

            // const code = get(response, 'code');

            // if (code === 0) {
            //     //success
            //     setResetCompleted(true)
            // } else if (code === '') {
            //     setResetCompleted(false)
            // }

        } catch (error) {
            console.error('error on reset password submit', error);
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const { Description, ForgotPasswordForm, Footer } = useMemo(() => {

        try {

            const state = getState(forgetPasswordDetails, setPaswordResponse);

            console.log('state', state, setPaswordResponse);

            const Description = get(descriptionComponentConfig, state) || Empty;

            const ForgotPasswordForm = get(formComponentConfig, state) || Empty;

            const Footer = get(footerComponentConfig, state) || Empty;

            return {
                Description,
                ForgotPasswordForm,
                Footer,
            }


        } catch (error) {
            return {
                Description: Empty,
                ForgotPasswordForm: Empty,
                Footer: Empty,
            }
        }
    }, [forgetPasswordDetails, setPaswordResponse])

    return (
        <ForgotPasswordContainer
            isLoading={isFetching || isUpdating}
            descriptionRenderProp={
                <Description {...forgetPasswordDetails} />
            }
            formRenderProp={
                <ForgotPasswordForm
                    resetPasswordAsync={resetPasswordAsync}
                    onFinishFailed={onFinishFailed} />
            }
            footerRenderProp={<Footer {...forgetPasswordDetails} />}
        />
    )


}