import React, { useEffect } from 'react';
// import PolarSvg from '../../vectors/xmas/polar_hi.svg';
import './XcChallengeFooter.scss';

const XcChallengeFooter = (props) => {
    return <div className="XcChallengeFooter">
        <ul className="XcChallengeFooterSocials">
            <li><a rel="noreferrer" target="_blank" href="https://www.facebook.com/onelearning.ro/"><img alt="Facebook" src="/images/social_icons/facebook.svg" /></a></li>
            <li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/onelearning.ro/"><img alt="Instagram" src="/images/social_icons/instagram.svg" /></a></li>
            <li><a rel="noreferrer" target="_blank" href=" https://www.tiktok.com/@onelearningro"><img alt="LinkedIn" src="/images/social_icons/tiktok.svg" /></a></li>
        </ul>
    </div>;
};

export default XcChallengeFooter;