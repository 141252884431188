import React from 'react';
import XcBackBlackSvg from '../../vectors/xmas/xcbackBlack.svg';
import './XcGuideWindow.scss';


const XcGuideWindow = (props) => {
    return <div className="XcGuideWindowContainer">
        <div className="XcGuideWindow">
            <div className="XcGuideWindowContent">
                <br/>
                <p>
                <span>❤️</span>
                <span>&nbsp;Am creat aceste 25 de activități simple și recreative cu intenția de a petrece timp de calitate împreună și să creați amintiri memorabile. </span>
                </p>
                <p>
                    <span>➡️</span>
                    <b>&nbsp;De ce avem nevoie? </b>
                </p>
                <p>
                    <span>10-15 minute împreună (fără telefon / alte distracții) și voie bună! Atât.</span>
                </p>
                <p>
                    <span>Puteți face activitățile oricând! Sperăm să vă placă, și puteți împărtăși cu noi experiența folosind #15MINDEJOACĂ. </span>
                </p>
                <p>
                    <span>✨</span>
                    <b>&nbsp;Activitățile</b>
                </p>
                <p>
                    <span>Timpul de joacă și expunerea la experiențe diverse din primii ani îi ajută pe cei mici să își dezvolte abilitățile esențiale pentru școală și viață. Deși poate par foarte simple și ușoare, acestea ajută la dezvoltarea holistică a copilului tău. </span>
                </p>
                <p>
                <span>❗</span>
                <span>Înainte să începeți - cum putem crea experiențe memorabile?</span>
                </p>
                <p>
                <h2>În această lume magică sunt câteva lucruri pe care să le luăm în calcul:</h2>
                </p>
                <ul>
                <li>
                    <span>Activitățile sunt sugestii pe care le puteți adapta circumstanțelor personale - dacă activitatea se transformă într-un alt joc sau ceva complet diferit - este ok, urmăriți flow-ul decât așteptările de cum ar trebui să arate aceea experiență</span>
                </li>
                <li>
                    <span>În joacă nu există un răspuns greșit - acest spațiu este de a experimenta, de a ne juca, de a ne imagina și de a fi noi înșiși, unde putem avea perspective diferite și să apreciem diversitatea în gândire</span>
                </li>
                <li>
                    <span>Stabiliți împreună când este mai bine pentru toată lumea de a se juca - dacă vă permite programul, pentru a forma un obicei, puteți aloca același timp pentru acest tip de activitate fie dimineața, la prânz sau seara </span>
                </li>
                <li>
                    <span>Arătăm apreciere unii către ceilalți și respect &nbsp;</span>
                </li>
                <li>
                    <span>Ghidează-te după copilul tău, incluzând ideile ei/lui și folosind “da, și…”</span>
                </li>
                <li>
                    <span>Dacă în timpul jocului apar comportamente neadecvate, rămâneți fermi și cu căldură puteți spune “Alege ceva nou ….”, asta înseamnă că trebuie să aleagă ceva nou pe loc. Amintiți-vă mereu să vă focusați pe joc și să apreciați ceea ce vedeți bun de fiecare dată! &nbsp;</span>
                </li>
                <li>
                    <span>Dacă copilul devine plictisit sau neliniștit puteți încorpora activități unde trebuie să se miște - sărituri pe loc sau genoflexiuni.</span>
                </li>
                </ul>
                <h2>
                    <span>💎</span>
                    <span>Sperăm că aceste momente să vă inspire și să aducă multe amintiri memorabile… </span>
                    <span>💕</span>
                    <span>&nbsp;Echipa ONE Learning. </span>
                </h2><br/><br/>
            </div>
            <div className="XcGuideWindowControls">
                <button onClick={props.onClose}>Close</button>
            </div>
        </div>
    </div>;
};

export default XcGuideWindow;
