import { useMutation } from "react-query";
import { appLinks, queryKeys } from "../../app/constants";
import { httpPostAsync } from "../../utils/httpHelpers";


function useVerifyEmail(verifyEmailToken) {

    const verifyEmailAsync = async () => {
        try {

            // console.log('env variables', process.env);

            const payload = { token: verifyEmailToken }

            const response = await httpPostAsync(appLinks.confirmEmailOneKids, payload);

            console.log('verify email response', response);

            return response;

        } catch (error) {
            console.log('error confirming email', error);
        }
    }

    const mutationQueryKey = [queryKeys.useVerifyEmail, verifyEmailToken]

    const {
        data: confirmEmailResponse,
        isLoading: isConfirming,
        mutateAsync: confirmEmailAsync,
    } = useMutation(
        verifyEmailAsync,
        { mutationKey: mutationQueryKey }
    );

    return {
        isConfirming,
        confirmEmailAsync,
        confirmEmailResponse,
    }

}


export default useVerifyEmail;