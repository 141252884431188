import React, { useState } from 'react';
import ReactGA from "react-ga4";
import classNames from 'classnames';
import FlyingSantaSvg from '../../vectors/xmas/flyingsanta.svg';
import TreesSvg from '../../vectors/xmas/trees.svg';
import BookSvg from '../../vectors/xmas/book.svg';
import LogoSvg from '../../vectors/xmas/onelearningpurple.svg';
import './xcRegister.scss';

const Register = (props) => {
    return <div className="XCRegister">
        <div className="XCBackground">
            <div className="XCLogoContainer">
                <img className="XCLogo" src={LogoSvg} alt="One Learning" />
            </div>
            <img className="XCTreesBg" alt="Trees" src={TreesSvg} />
            <img className="XCFlyingSanta" alt="Fly santa" src={FlyingSantaSvg} />
            <h1>Enter your details to take part in<br/> our Christmas challenge...</h1>
        </div>
        <div className="XCForeground">
            <div className="XCHeader">
            </div>
            <div className="XCBook">
                <div className="XCBookBackground">
                    <img className="XCBookSvg" src={BookSvg} alt="Registration book" />
                </div>
            </div>
        </div>
    </div>;
};

export default Register;
