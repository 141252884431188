import React from 'react';
import './XcAppPromo.scss';

const XcAppPromo = (props) => {
    return <div className="XcAppPromoContainer">
        <div className="XcAppPromo">
            <div className="XcAppPromo__text">
                <p>Pregătim o aplicație simpatică unde puteți accesa peste 100+ activăți practice și personalizate (off-screen) pentru tine și copilul tău prin care să îl ajuți să-și dezvolte abilitățile esențiale pentru școală și viată.</p>
                <p>Află mai multe pe: <a href="https://onelearning.ro/" target="_blank" rel="noreferrer">onelearning.ro</a></p>
            </div>
            <div className="XcAppPromo__image">
                <img alt="Preview" src="./images/kids/banner.png" />
            </div>
        </div>
    </div>;
};

export default XcAppPromo;