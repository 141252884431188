import React from 'react';
import './PrivacyRo.scss';

const PrivacyRo = () => {
    return <div className="PrivacyRo" >
        <div className="PrivacyRo__head">
            <h1>Protecția datelor dumneavoastră</h1>
            <img alt="Protecția datelor dumneavoastră" src="/images/terms.png" />
        </div>

        <div>Website-ul nostru respectă în totalitate politicile de protectie a datelor cu caracter personal stabilite prin GDPR (<a rel="noreferrer" target="_blank" href="https://eur-lex.europa.eu/legal-content/RO/TXT/?uri=celex%3A32016R0679">Regulamentul general privind protecția datelor - Regulamentul UE 2016/679</a>)<br />
        <br />
        <strong>1. CE INFORMAȚII COLECTĂM?</strong></div>

        <div>Informațiile personale pe care ni le dezvăluiți</div>

        <div>Pe scurt: &nbsp;Colectăm informațiile personale pe care ni le furnizați.&nbsp;</div>

        <div>Colectăm informații personale pe care ni le furnizați în mod voluntar atunci când dvs înregistrează-te pe &nbsp;aplicație, &nbsp;vă exprimați interesul de a obține informații despre noi sau despre produsele și serviciile noastre, atunci când participați la activități pe site App &nbsp;sau altfel atunci când ne contactați.</div>

        <div>Informațiile personale pe care le colectăm depind de contextul interacțiunilor dumneavoastră cu noi și de App , alegerile pe care le faceți și produsele și funcțiile pe care le utilizați. Informațiile personale pe care le colectăm pot include următoarele:</div>

        <div>Informații personale furnizate de dvs. Colectăm nume de utilizator; &nbsp;parolele; &nbsp;adrese de email; &nbsp;nume; &nbsp;și alte informații similare.</div>

        <div>Toate informațiile personale pe care ni le furnizați trebuie să fie adevărate, complete și exacte și trebuie să ne notificați cu privire la orice modificare a acestor informații personale.</div>

        <div>Informații colectate prin aplicația noastră</div>

        <div>Pe scurt: &nbsp;Colectăm informații cu privire la dvs &nbsp;Notificări, atunci când utilizați aplicația noastră.</div>

        <div>Dacă utilizați aplicația noastră, colectăm și următoarele informații:<br />
        Notificări. Este posibil să vă solicităm să vă trimitem notificări push cu privire la contul dumneavoastră sau la anumite caracteristici ale aplicației. Dacă doriți să renunțați la primirea acestor tipuri de comunicări, le puteți dezactiva din setările dispozitivului dvs.<br />
        Aceste informații sunt necesare în primul rând pentru a menține securitatea și funcționarea aplicației noastre, pentru depanare și pentru scopurile noastre interne de analiză și raportare.<br />
        &nbsp;</div>

        <div><strong>2. CUM UTILIZAM INFORMATIILE DVS.?</strong></div>

        <div>Pe scurt: &nbsp; prelucrăm informațiile dumneavoastră în scopuri bazate pe interese legitime de afaceri, îndeplinirea contractului nostru cu dumneavoastră, respectarea obligațiilor noastre legale și/sau consimțământul dumneavoastră.</div>

        <div>Folosim informațiile personale colectate prin intermediul nostru App pentru o varietate de scopuri de afaceri descrise mai jos. Prelucrăm informațiile dumneavoastră personale în aceste scopuri, bazându-ne pe interesele noastre legitime de afaceri, pentru a încheia sau a îndeplini un contract cu dumneavoastră, cu consimțământul dumneavoastră și/sau pentru a ne conforma obligațiilor legale. Indicăm motivele specifice de prelucrare pe care ne bazăm lângă fiecare scop enumerat mai jos.</div>

        <div>Folosim informațiile pe care le colectăm sau le primim:<br />
        Pentru a facilita crearea contului și procesul de conectare. Dacă alegeți să vă conectați contul cu noi la un cont terță parte (cum ar fi contul dvs. Google sau Facebook), folosim informațiile pe care ne-ați permis să le colectăm de la acele terțe părți pentru a facilita crearea contului și procesul de conectare pentru realizarea contracta.<br />
        Pentru a posta mărturii. Postăm mărturii pe site-ul nostru App care pot conține informații personale. Înainte de a publica o mărturie, vom obține consimțământul dumneavoastră pentru a vă folosi numele și conținutul mărturiei. Dacă doriți să actualizați sau să ștergeți mărturia dvs., vă rugăm să ne contactați la contact@onelearning.app și asigurați-vă că includeți numele dvs., locația mărturiei și informațiile de contact.<br />
        Solicitați feedback. Putem folosi informațiile dvs. pentru a solicita feedback și pentru a vă contacta cu privire la utilizarea de către dvs. a noastră App .<br />
        Pentru a activa comunicațiile de la utilizator la utilizator. Putem folosi informațiile dvs. pentru a permite comunicațiile de la utilizator la utilizator cu acordul fiecărui utilizator.<br />
        Pentru a gestiona conturile de utilizator. Putem folosi informațiile dumneavoastră în scopul gestionării contului nostru și al menținerii acestuia în stare de funcționare.<br />
        &nbsp;</div>

        <div><strong>3. INFORMAȚIA DVS. VOR FI ÎMPĂRȚĂTĂ CU CINEVA?</strong></div>

        <div>Pe scurt: &nbsp; împărtășim informații doar cu consimțământul dumneavoastră, pentru a respecta legile, pentru a vă oferi servicii, pentru a vă proteja drepturile sau pentru a vă îndeplini obligațiile de afaceri.</div>

        <div>Putem prelucra sau partaja datele dumneavoastră pe care le deținem pe baza următorului temei legal:<br />
        Consimțământ: putem prelucra datele dumneavoastră dacă ne-ați dat consimțământul specific pentru a vă folosi informațiile personale într-un anumit scop.<br />
        Interese legitime: putem prelucra datele dumneavoastră atunci când este necesar în mod rezonabil pentru a ne atinge interesele legitime de afaceri.<br />
        Executarea unui contract: În cazul în care am încheiat un contract cu dumneavoastră, este posibil să procesăm informațiile dumneavoastră personale pentru a îndeplini termenii contractului nostru.<br />
        Obligații legale: putem dezvălui informațiile dvs. acolo unde suntem obligați legal să facem acest lucru pentru a ne conforma cu legea aplicabilă, cererile guvernamentale, o procedură judiciară, o hotărâre judecătorească sau un proces legal, cum ar fi ca răspuns la o hotărâre judecătorească sau o citație ( inclusiv ca răspuns la autoritățile publice pentru a îndeplini cerințele de securitate națională sau de aplicare a legii).<br />
        Interese vitale: putem dezvălui informațiile dumneavoastră atunci când considerăm că este necesar să investigăm, să prevenim sau să luăm măsuri cu privire la potențialele încălcări ale politicilor noastre, suspecte de fraudă, situații care implică potențiale amenințări la adresa siguranței oricărei persoane și activități ilegale sau ca dovadă în litigii în care suntem implicați.<br />
        Mai precis, este posibil să fie nevoie să vă procesăm datele sau să vă împărtășim informațiile personale în următoarele situații:<br />
        Transferuri de afaceri. Putem împărtăși sau transfera informațiile dumneavoastră în legătură cu sau în timpul negocierilor cu privire la orice fuziune, vânzare de active ale companiei, finanțare sau achiziție a întregii sau a unei părți din afacerea noastră către o altă companie.<br />
        &nbsp;</div>

        <div><strong>4. UTILIZAM COOKIE</strong></div>

        <div>Pe scurt: &nbsp; Este posibil să folosim module cookie și alte tehnologii de urmărire pentru a colecta și stoca informațiile dvs.</div>

        <div>Este posibil să folosim module cookie și tehnologii similare de urmărire (cum ar fi semnalizatoarele web și pixelii) pentru a accesa sau stoca informații. Informații specifice despre modul în care utilizăm astfel de tehnologii și despre cum puteți refuza anumite module cookie sunt prezentate în Notificarea noastră privind cookie-urile .<br />
        &nbsp;</div>

        <div><strong>5. CÂT TIMP PĂSTRĂM INFORMAȚIILE DVS.?</strong></div>

        <div>Pe scurt: &nbsp; Păstrăm informațiile dumneavoastră atâta timp cât este necesar pentru a îndeplini scopurile prezentate în această notificare de confidențialitate, cu excepția cazului în care legea prevede altfel.</div>

        <div>Vom păstra informațiile dumneavoastră personale doar atâta timp cât este necesar pentru scopurile prevăzute în această notificare de confidențialitate, cu excepția cazului în care o perioadă mai lungă de păstrare este cerută sau permisă de lege (cum ar fi cerințe fiscale, contabile sau alte cerințe legale). Niciun scop din această notificare nu va cere să păstrăm informațiile dumneavoastră personale mai mult de perioada de timp în care utilizatorii au un cont la noi .</div>

        <div>Atunci când nu avem nevoie de afaceri legitime în desfășurare de a vă procesa informațiile personale, vom șterge sau anonimiza aceste informații sau, dacă acest lucru nu este posibil (de exemplu, deoarece informațiile dvs. personale au fost stocate în arhive de rezervă), atunci vom fi în siguranță. stocați informațiile dumneavoastră personale și izolați-le de orice procesare ulterioară până când este posibilă ștergerea.<br />
        &nbsp;</div>

        <div><strong>6. CUM PĂSTRĂM INFORMAȚIILE DVS. ÎN SIGURANȚĂ?</strong></div>

        <div>Pe scurt: &nbsp; Ne propunem să vă protejăm informațiile personale printr-un sistem de măsuri de securitate organizatorică și tehnică.</div>

        <div>Am implementat măsuri de securitate tehnice și organizatorice adecvate menite să protejeze securitatea oricăror informații personale pe care le procesăm. Cu toate acestea, în ciuda măsurilor noastre de protecție și a eforturilor noastre de a vă securiza informațiile, nicio transmisie electronică prin Internet sau tehnologia de stocare a informațiilor nu poate fi garantată a fi 100% sigură, așa că nu putem promite sau garanta că hackerii, criminalii cibernetici sau alte terțe părți neautorizate nu vor fi capabil să ne învingă securitatea și să colecteze, să acceseze, să fure, sau modificați informațiile dvs. Deși vom face tot posibilul pentru a vă proteja informațiile personale, transmiterea informațiilor personale către și de la noi App este pe propriul risc. Ar trebui să accesați doar App într-un mediu sigur.<br />
        &nbsp;</div>

        <div><strong>7. CARE SUNT DREPTURILE DVS. DE CONFIDENTIALITATE?</strong></div>

        <div>În scurt: &nbsp;În unele regiuni, cum ar fi Spațiul Economic European (SEE) și Regatul Unit (Marea Britanie), aveți drepturi care vă permit un acces mai mare la informațiile dvs. personale și un control asupra acestora. Vă puteți revizui, modifica sau închide contul în orice moment.</div>

        <div>În unele regiuni (cum ar fi SEE și Regatul Unit), aveți anumite drepturi în temeiul legilor aplicabile privind protecția datelor. Acestea pot include dreptul (i) de a solicita acces și de a obține o copie a informațiilor dumneavoastră personale, (ii) de a solicita rectificarea sau ștergerea; (iii) pentru a restricționa prelucrarea informațiilor dumneavoastră personale; și (iv) dacă este cazul, portabilității datelor. În anumite circumstanțe, este posibil să aveți și dreptul de a vă opune prelucrării informațiilor dumneavoastră personale. Pentru a face o astfel de solicitare, vă rugăm să utilizațidatele de contact furnizate mai jos. Vom lua în considerare și vom acționa la orice solicitare în conformitate cu legile aplicabile privind protecția datelor.</div>

        <div>Dacă ne bazăm pe consimțământul dumneavoastră pentru a vă prelucra informațiile personale, aveți dreptul de a vă retrage consimțământul în orice moment. Vă rugăm să rețineți totuși că acest lucru nu va afecta legalitatea prelucrării înainte de retragerea acesteia și nici nu va afecta prelucrarea informațiilor dumneavoastră cu caracter personal efectuată pe baza unor motive legale de prelucrare, altele decât consimțământul.<br />
        &nbsp;<br />
        Dacă sunteți rezident în SEE sau Regatul Unit și credeți că prelucrăm ilegal informațiile dvs. personale, aveți, de asemenea, dreptul de a vă plânge la autoritatea locală de supraveghere a protecției datelor. Puteți găsi datele de contact ale acestora aici: https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm .</div>

        <div>Dacă sunteți rezident în Elveția, datele de contact ale autorităților de protecție a datelor sunt disponibile aici: https://www.edoeb.admin.ch/edoeb/en/home.html .</div>

        <div>Dacă aveți întrebări sau comentarii despre drepturile dumneavoastră de confidențialitate, ne puteți trimite un e-mail la adresa contact@onelearning.app .<br />
        &nbsp;</div>

        <div>informatii despre cont</div>

        <div>Dacă doriți în orice moment să revizuiți sau să modificați informațiile din contul dvs. sau să vă închideți contul, puteți:<br />
        Contactați-ne folosind informațiile de contact furnizate.<br />
        La solicitarea dvs. de a vă închide contul, vă vom dezactiva sau șterge contul și informațiile din bazele noastre de date active. Cu toate acestea, este posibil să reținem anumite informații în fișierele noastre pentru a preveni frauda, ​​pentru a depana probleme, pentru a asista în orice investigație, pentru a aplica Termenii noștri de utilizare și/sau pentru a ne conforma cerințelor legale aplicabile.</div>

        <div>Renunțarea la marketingul prin e-mail:Vă puteți dezabona de la lista noastră de e-mailuri de marketing în orice moment făcând clic pe linkul de dezabonare din e-mailurile pe care le trimitem sau contactându-ne folosind detaliile furnizate mai jos. Veți fi apoi eliminat din lista de e-mailuri de marketing - cu toate acestea, este posibil să comunicăm în continuare cu dvs., de exemplu pentru a vă trimite e-mailuri legate de servicii care sunt necesare pentru administrarea și utilizarea contului dvs., pentru a răspunde solicitărilor de servicii sau pentru alte scopuri non-marketing. Pentru a renunța altfel, puteți:<br />
        Contactați-ne folosind informațiile de contact furnizate.<br />
        &nbsp;</div>

        <div><strong>8. CONTROALE PENTRU CARACTERISTICI NU PENTRU TRACK</strong></div>

        <div>Majoritatea browserelor web și unele sisteme de operare și aplicații mobile includ o funcție Do-Not-Track ("DNT") sau o setare pe care o puteți activa pentru a semnala preferința dvs. de confidențialitate de a nu avea date despre activitățile dvs. de navigare online monitorizate și colectate. În această etapă nu a fost finalizat niciun standard tehnologic uniform pentru recunoașterea și implementarea semnalelor DNT. Ca atare, În prezent, nu răspundem la semnalele browserului DNT sau la orice alt mecanism care comunică automat alegerea dvs. de a nu fi urmărit online. Dacă se adoptă un standard de urmărire online pe care trebuie să-l respectăm în viitor, vă vom informa despre această practică într-o versiune revizuită a acestei notificări de confidențialitate.&nbsp;<br />
        &nbsp;</div>

        <div>Drepturile dumneavoastră cu privire la datele dumneavoastră personale</div>

        <div>Dreptul de a solicita ștergerea datelor - Solicitare de ștergere</div>

        <div>Puteți cere ștergerea informațiilor dumneavoastră personale. Dacă ne cereți să vă ștergem informațiile personale, vă vom respecta solicitarea și vă vom șterge informațiile personale, sub rezerva anumitor excepții prevăzute de lege, cum ar fi (dar fără a se limita la) exercitarea de către un alt consumator a dreptului său la libertatea de exprimare , cerințele noastre de conformitate care rezultă dintr-o obligație legală sau din orice prelucrare care poate fi necesară pentru a proteja împotriva activităților ilegale.</div>

        <div><br />
        Dreptul de a fi informat - Cerere de a cunoaște</div>

        <div>În funcție de circumstanțe, aveți dreptul să știți:<br />
        dacă colectăm și folosim informațiile dumneavoastră personale;<br />
        categoriile de informații personale pe care le colectăm;<br />
        scopurile pentru care sunt utilizate informațiile personale colectate;<br />
        dacă vindem informațiile dumneavoastră personale către terți;<br />
        categoriile de informații personale pe care le-am vândut sau dezvăluit în scop de afaceri;<br />
        categoriile de terți cărora le-au fost vândute sau dezvăluite informațiile personale în scop comercial; și<br />
        scopul comercial sau comercial pentru colectarea sau vânzarea de informații personale.<br />
        În conformitate cu legea aplicabilă, nu suntem obligați să furnizăm sau să ștergem informații despre consumator care sunt de-identificate ca răspuns la o solicitare a consumatorului sau să reidentificăm datele individuale pentru a verifica o solicitare a consumatorului.</div>

        <div><br />
        Dreptul la nediscriminare pentru exercitarea drepturilor la confidențialitate ale consumatorului</div>

        <div>Nu vă vom discrimina dacă vă exercitați drepturile de confidențialitate.<br />
        &nbsp;</div>

        <div>Proces de verificare</div>

        <div>La primirea cererii dvs., va trebui să vă verificăm identitatea pentru a stabili că sunteți aceeași persoană despre care avem informațiile în sistemul nostru. Aceste eforturi de verificare ne impun să vă cerem să furnizați informații, astfel încât să le putem potrivi cu informațiile pe care ni le-ați furnizat anterior. De exemplu, în funcție de tipul de cerere pe care o trimiteți, vă putem solicita să furnizați anumite informații, astfel încât să putem potrivi informațiile pe care le furnizați cu informațiile pe care le avem deja în dosar, sau vă putem contacta printr-o metodă de comunicare (de exemplu, telefon sau e-mail) pe care ni le-ați furnizat anterior. Putem folosi și alte metode de verificare, după cum ne impun circumstanțele.</div>

        <div>Vom folosi numai informațiile personale furnizate în cererea dumneavoastră pentru a vă verifica identitatea sau autoritatea de a face cererea. În măsura în care este posibil, vom evita să vă solicităm informații suplimentare în scopul verificării. Dacă, totuși, nu vă putem verifica identitatea din informațiile deja păstrate de noi, vă putem solicita să furnizați informații suplimentare în scopul verificării identității dvs. și în scopuri de securitate sau de prevenire a fraudei.<br />
        &nbsp;</div>

        <div>Alte drepturi de confidențialitate<br />
        vă puteți opune prelucrării datelor dumneavoastră cu caracter personal.<br />
        puteți solicita corectarea datelor dumneavoastră cu caracter personal dacă acestea sunt incorecte sau nu mai sunt relevante sau puteți cere restricționarea procesării datelor.<br />
        puteți desemna un agent autorizat pentru a face o cerere conform CCPA în numele dvs. Este posibil să respingem o solicitare din partea unui agent autorizat care nu prezintă dovada că acesta a fost autorizat în mod valid să acționeze în numele dvs. în conformitate cu CCPA.<br />
        puteți solicita să renunțați la vânzarea viitoare a informațiilor dumneavoastră personale către terți. La primirea unei solicitări de renunțare, vom acționa asupra cererii cât mai curând posibil, dar nu mai târziu de 15 zile de la data depunerii cererii.<br />
        Pentru exercitarea acestor drepturi, ne puteți contacta &nbsp;prin email lacontact@onelearning.app , sau prin referire la datele de contact din partea de jos a acestui document. Dacă aveți o plângere cu privire la modul în care gestionăm datele dvs., am dori să primim informații de la dvs. &nbsp;<br />
        &nbsp;</div>

        <div><strong>9. FACEM ACTUALIZĂRI LA ACEST ANUNȚ? &nbsp; </strong>&nbsp;&nbsp;</div>

        <div>Pe scurt: &nbsp;Da, vom actualiza această notificare după cum este necesar pentru a rămâne în conformitate cu legile relevante.</div>

        <div>Este posibil să actualizăm această notificare de confidențialitate din când în când. Versiunea actualizată va fi indicată printr-o dată actualizată „Revizuită”, iar versiunea actualizată va intra în vigoare de îndată ce este accesibilă. Dacă aducem modificări semnificative acestei notificări de confidențialitate, vă putem notifica fie publicând vizibil o notificare cu privire la astfel de modificări, fie trimițându-vă direct o notificare. Vă încurajăm să examinați frecvent această notificare de confidențialitate pentru a fi informat despre modul în care vă protejăm informațiile.<br />
        &nbsp;</div>

        <div><strong>10. CUM NE PUTEȚI CONTACTA ÎN PRIVIND ACEST ANUNȚ? </strong>&nbsp; &nbsp;&nbsp;</div>

        <div>Dacă aveți întrebări sau comentarii despre această notificare, puteți trimite-ne un e-mail lacontact@onelearning.app &nbsp;sau prin posta la:</div>

        <div>Moonshoot Global Consulting Ltd<br />
        Suite2A Blackthorn House, St Pauls Square Birmingham, West Midlands, B3 1RL<br />
        Regatul Unit<br />
        &nbsp;</div>

        <div><strong>11. CUM PUTEȚI REVIZUI, ACTUALIZA SAU ȘTERGE DATELE PE CARE LE COLECTEM DE LA DVS.? &nbsp; &nbsp;</strong>&nbsp;</div>

        <div>Pe baza legilor aplicabile din țara dvs., este posibil să aveți dreptul de a solicita acces la informațiile personale pe care le colectăm de la dvs., de a modifica acele informații sau de a le șterge în anumite circumstanțe. Pentru a solicita revizuirea, actualizarea sau ștergerea informațiilor dvs. personale, vă rugăm să ne trimiteți un e-mail la contact@onelearning.app.</div>

    </div>;
};

export default PrivacyRo;
