import _ from 'lodash';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import XHR from 'i18next-xhr-backend';
import { __ } from '../index';

moment.locale(window.navigator.language);
const lng = 'en';
const notFoundPostProcessor = {
  name: 'hideNotFoundKey',
  type: 'postProcessor',
  process(value, key, opts, translator) {
    // we want the 'not found' key to display in catalogues, but *not*
    // on the UI, to do this, we intercept any not found keys and fetch
    // the default (English) translation.
    if (value === '__STRING_NOT_TRANSLATED__') {
      return __(key[0], { ...opts, lng });
    }
    return value;
  },
};

const options = {
  backend: {
    loadPath: `/locales/{{lng}}/{{ns}}.json?r=${Math.round(Math.random()*1000)}`
  },
  debug: false,
  defaultNS: 'translation',
  fallbackLng: lng,
  initImmediate: false,
  interpolation: {
    escapeValue: false,
    format(value, format) {
      if (value instanceof Date) {
        return moment(value).format(format);
      }
      return value;
    },
  },
  keySeparator: false,
  lng: 'en',
  ns: ['translation'],
  nsSeparator: false,
  postProcess: 'hideNotFoundKey',
  react: {
    defaultTransParent: 'div',
    wait: true,
  },
};

export default (args, cb, exOptions) =>
  i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(notFoundPostProcessor)
    .init(_.merge({}, {...options, ...(exOptions || {})}, args), cb);
