import { Card, Col, Layout, Row, Spin } from "antd";
import { useIsDesktopLarge } from "../Challenge/hooks/media";
import Logo from '../../vectors/logomin.svg';
import React, { useEffect, useMemo, useState } from "react";

const { Content, Header } = Layout;

export default function ForgotPasswordContainer({
    isLoading = false,
    descriptionRenderProp,
    formRenderProp,
    footerRenderProp,
}) {

    const [desktopscreen, setDesktopScreen] = useState(false);
    const { isDesktopLarge } = useIsDesktopLarge();

    useEffect(() => {

        setDesktopScreen(isDesktopLarge)

    }, [isDesktopLarge])

    console.log('screen is desktop or not ', desktopscreen);

    // conditions
    // error getting forgot password details, if there are is an code, then show error
    // error when resetting password
    // if forgetPasswordDetails.code show error and no reset password form
    // if response.code !== 0, when setting password, different screen
    // nor just show forward password success


    const contentStyles = useMemo(() => {
        return desktopscreen ? {
            paddingTop: '4em', height: '100vh', backgroundColor: 'rgb(200 221 254)'
        } : {
            paddingTop: '4em', backgroundColor: 'rgb(200 221 254)', paddingBottom: '10em'
        }
    }, [desktopscreen])

    useEffect(() => {
        console.log('content styles : ', contentStyles);
    }, [contentStyles])


    return (
        <div className=" antdContainer">
            <div className="forgotPaswordContainer forgotPasswordNamespace">
                <Layout>
                    <Spin spinning={isLoading}>
                        <Header style={{ backgroundColor: '#fff' }}>
                            <img src={Logo} alt='logo image' height='38px'
                                style={{ marginLeft: '5em', }}
                            />
                        </Header>
                        <Content style={contentStyles}>
                            <div className="desktop">
                                <Row justify="center">
                                    <Col span={12}>
                                        <Card style={{ padding: 0, marginBottom: '4em ' }}>
                                            <Row className="desktopCoverDisplay"  >
                                                <Col span={24} style={{ padding: '2.5em 2em', paddingBottom: '1em' }}>
                                                    {descriptionRenderProp}
                                                    {formRenderProp}
                                                </Col>
                                            </Row>
                                            <Row>
                                                {footerRenderProp}
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Content>
                    </Spin>
                </Layout>
            </div>
        </div>
    )
}