import { Component } from 'react';
import ReactDOM from 'react-dom';

class ModalPortal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    document.getElementById('modal-portal').appendChild(this.el);
  }

  componentWillUnmount() {
    document.getElementById('modal-portal').removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default ModalPortal;
