import React, { useContext } from 'react';
import RegionContext from '../../contexts/RegionContext';
import { __ } from '../../i18n';

const DefaultFooter = (props) => {
    const { hideContact } = props
    const region = useContext(RegionContext);
    return (
        <div id="contact" className="GradientSection">
            {
                !hideContact ?
                    (
                        <div>
                            <div className="Content">
                                <h1>{__('Contact')}</h1>
                                <ul className="Address">
                                    <li>MOONSHOT GLOBAL CONSULTING LTD</li>
                                    <li>Suite2a Blackthorn House</li>
                                    <li>St Pauls Square</li>
                                    <li>Birmingham, West Midlands B3 1RL</li>
                                    <li>contact@onelearning.app</li>
                                </ul>
                            </div>
                        </div>
                    )
                    : <div className='contact-placeholder'/>
            }
            <div className="Footer">© {__(`2024 ONE Learning App. All Rights Reserved`)} &bull; <a target="_blank" rel="noreferrer" href={`/${region.code}/terms`}>{__(`Terms of use`)}</a> &bull; <a target="_blank" rel="noreferrer" href={`/${region.code}/privacy`}>{__(`Privacy`)}</a></div>
        </div>
    );
};

export default DefaultFooter;
