
import HeartIcon from '../../images/common/heart.svg';
import ClockIcon from '../../images/common/icons8-clock.svg';
import LocationIcon from '../../images/common/location.svg';
import SparkIcon from '../../images/common/spark1.svg';

const Header = (props) => {

    const {
        title,
        likes,
        location,
        duration,
        sparks,
        oneValues,
    } = props

    return (
        <div>
            <div className="title-column">
                <h1>{title}</h1>
                <div className='likes-container'>
                    <img src={HeartIcon} className='like-icon' />
                    <p className='likes-count'>{likes}</p>
                </div>
            </div>
            <div className='stats-container'>
                <div className='location-item'>
                    <img className='location-icon' src={LocationIcon} />
                    <p className='location-title'>{location}</p>
                </div>
                <div className='clock-item'>
                    <img className='clock-icon' src={ClockIcon} />
                    <p className='clock-title'>{duration}</p>
                </div>
                <div className='spark-item'>
                    <img className='spark-icon' src={SparkIcon} />
                    <p className='spark-title'>{sparks}</p>
                </div>
            </div>
            <div className='value-tags'>
                {
                    oneValues?.map(theme => {

                        //console.log('theme', theme)

                        return (
                            <div 
                            key={theme.key}
                            className='value-item'
                            style={{
                                backgroundColor: theme.color
                            }}
                            >
                                <p>{theme.value}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )

}

export default Header;