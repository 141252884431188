import React, { useState } from "react";
import { Popover } from 'react-tiny-popover'
import cx from 'classnames';
import { t } from '../../utils/Translation';
import RoFlag from '../../vectors/flags/roflag.svg';
import UkFlag from '../../vectors/flags/ukflag.svg';
import './style.scss';

const pages = {
    'toEn': {
        'desprenoi': 'aboutus'
    },
    'toRo': {
        'aboutus': 'desprenoi'
    }
};

const LangMenu = ({ onSelect }) => {

    const [langMenuOpen, setLangMenuOpen] = useState(false);

    return <Popover
    isOpen={langMenuOpen}
    positions={['bottom']}
    containerStyle={{ left: '0' }}
    onClickOutside={() => setLangMenuOpen(false)}
    content={<ul className="LangSelectMenu">
        <li style={{ fontWeight: window.location.pathname.indexOf('/ro') < 0 ? 'bold' : null }} onClick={() => {
            if (typeof onSelect === 'function') {
                onSelect('en')
                setLangMenuOpen(false)
            } else {
                const pathSplit = window.location.pathname.split('/');
                if (pathSplit.length > 2) {
                    window.location.href = `/en/${pages.toEn[pathSplit[2]]}`
                    return;
                }
                window.location.href = '/en';
            }
        }}>
            <span>{t('English')}</span><img height="20" src={UkFlag} alt={t('English')} />
        </li>
        <li style={{ fontWeight: window.location.pathname.indexOf('/ro') > -1 ? 'bold' : null }} onClick={() => {
             if (typeof onSelect === 'function') {
                onSelect('ro')
                setLangMenuOpen(false)
            } else {
                const pathSplit = window.location.pathname.split('/');
                if (pathSplit.length > 2) {
                    window.location.href = `/ro/${pages.toRo[pathSplit[2]]}`
                    return;
                }
                window.location.href = '/ro';
            }
          
        }}>
            <span>{t('Romanian')}</span><img height="20" src={RoFlag} alt={t('Romanian')} />
        </li>
    </ul>}
  >
    <div className={cx({
        "LangSelectButton": true,
        "LangSelectButton--open": langMenuOpen,
    })} onClick={() => setLangMenuOpen(!langMenuOpen)}>
        {window.location.href.indexOf('/ro') > -1 ? <>
            <span>Romanian</span>
            <img height="20" src={RoFlag} alt={t('Romanian')} />
        </> : <>
            <span>English</span>
            <img height="20" src={UkFlag} alt={t('English')} />
        </>}
    </div>
  </Popover>;
}

export default LangMenu;