import axios from "axios";
import { useQuery } from "react-query";
import { appLinks, languageCode, queryKeys } from "../../app/constants";
import Header from "./header";

import { useEffect } from "react";
import CheckIcon from '../../images/common/green-check.svg';
import { useIsDesktopOrLaptop, useVerySmall } from "../Challenge/hooks/media";

import whiteimage from '../../images/common/640x360.png';



const ShareView = ({
    playmomentId,
    setPlaymomentLanguage,
    translate,
}) => {


    const getPlaymomentDetailsAsync = async () => {
        try {

            const link = appLinks.playmomentDetails.replace('{id}', playmomentId)

            const parsedResponse = await axios.post(link)

            console.log('parsed response', parsedResponse)

            return parsedResponse?.data?.data

        } catch (error) {
            console.log('error getting playmoment details', error)
        }
    }

    const {
        data,
        isLoading,
        //refetch
    } = useQuery(
        [queryKeys.playmomentDetails, playmomentId],
        getPlaymomentDetailsAsync
    );

    const { isDesktopOrLaptop } = useIsDesktopOrLaptop()
    const { isVerySmall } = useVerySmall()

    const playmomentLangauge = data?.language ?? languageCode.en

    useEffect(() => {
        setPlaymomentLanguage(playmomentLangauge)
    }, [playmomentLangauge])

    const title = data?.title
    const likes = data?.likes
    const location = data?.location
    const duration = data?.duration
    const sparks = data?.sparks
    const oneValues = data?.oneValues
    const imageUrl = data?.imageUrl
    //const description = data?.description
    const intro = data?.intro
    const character = data?.character

    return (
        <div className={isDesktopOrLaptop ? "share-inner-container-row" : "share-inner-container-col"}>
            <div className="playmoment-image-container" >
                <div className="image-container">
                    <img className="playmoment-image" src={imageUrl} />
                    <img className="placeholder" src={whiteimage} style={{
                        backgroundColor: data?.backgroundColor ?? 'white'
                    }} />
                    <div className="background-color"
                        style={{ backgroundColor: data?.backgroundColor ?? 'white' }}
                    />
                    <img className="character-image" src={`https://onelearning.app/images/characters/${character}.png`} />
                </div>
            </div>
            <div className="content-container">
                <Header
                    title={title}
                    likes={likes}
                    location={location}
                    duration={duration}
                    sparks={sparks}
                    oneValues={oneValues}
                />
                <div className="description-container">
                    <p className="description-text">{intro}</p>
                </div>
                <div className="download-title-container">
                    <h1 className="title">{translate('Download now to view this play moment...')}</h1>
                </div>
                <div className="download-actions">
                    <a href="https://play.google.com/store/apps/details?id=app.onelearning.onekids">
                        <img className={isVerySmall ? "download-image" : "download-image-small"} src="https://onelearning.app/images/download/playstore.png" alt="Playstore" />
                    </a>
                    <a href="https://apps.apple.com/us/app/one-learning-kids/id6443809900">
                        <img className={isVerySmall ? "download-image" : "download-image-small"} src="https://onelearning.app/images/download/appstore.png?r=100" alt="App store" />
                    </a>
                </div>
                <div className="points-container">
                    <ul>
                        <li className="point-item">
                            <img className='check-icon' src={CheckIcon} />
                            <span className="item-title">{translate('Spend quality family time')}</span>
                        </li>
                        <li className="point-item">
                            <img className='check-icon' src={CheckIcon} />
                            <span className="item-title">{translate('Learn and grow')}</span>
                        </li>
                        <li className="point-item">
                            <img className='check-icon' src={CheckIcon} />
                            <span className="item-title">{translate('Experience the magic of play')}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )

}

export default ShareView;