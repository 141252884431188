import { createContext } from 'react';

export const regions = {
    'EN': {
      code: 'en',
      currency: 'GBP',
      subscribeURL: 'https://app.mailingboss.com/lists/63d16f7a2a807/subscribe'
    },
    'RO': {
      code: 'ro',
      currency: 'RON',
      subscribeURL: 'https://app.mailingboss.com/lists/63e8c811b0b7f/subscribe'
    },
  };

const RegionContext = createContext(null);
export default RegionContext;
