import React, { useEffect } from 'react';
import PolarSvg from '../../vectors/xmas/polar_hi.svg';
import ClockIcon from '../Challenge/common/ClockIcon';
import MedalIcon from '../Challenge/common/MedalIcon';
import DifficultyIcon from '../Challenge/common/DifficultyIcon';
import './XcChallengeSummary.scss';

const isBright = (color) => {
    var r, g, b, hsp;
    if (color.match(/^rgb/)) {
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        r = color[1];
        g = color[2];
        b = color[3];
    } 
    else {
        color = +("0x" + color.slice(1).replace( 
        color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
    }
    
    hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
    );

    return hsp>127.5;
}

const XcChallengeSummary = (props) => {
    const { data } = props;
    return <div className="XcChallengeSummary">
        <div className="XcChallengeSummary__image">
            <img src={data.imageUrl} width="100%" alt="Imag" />
        </div>
        <div className="XcChallengeSummary_title">{data.title}</div>
        <ul className="XcChallengeSummary__stats">
            <li className="XcChallengeSummaryStat">
                <span className="XcChallengeSummaryStat__icon"><ClockIcon /></span>
                <span className="XcChallengeSummaryStat__value">{data.duration}</span>
            </li>
            <li className="XcChallengeSummaryStat">
                <span className="XcChallengeSummaryStat__icon"><MedalIcon /></span>
                <span className="XcChallengeSummaryStat__value">{data.xp} XP</span>
            </li>
            <li className="XcChallengeSummaryStat">
                <span className="XcChallengeSummaryStat__icon"><DifficultyIcon /></span>
                <span className="XcChallengeSummaryStat__value">{data.difficulty}</span>
            </li>
        </ul>
        <ul className="XcChallengeSummary__skills">
            {data.skills.map(s => <li style={{ background: s.color, color: isBright(s.color) ? '#000' : '#fff'  }} className="XcChallengeSummarySkill">{s.label}</li>)}
        </ul>
        {data.customIntro && <div className="XcChallengeSummary_intro">
            <h3>Povestea lui Star</h3>
            <p>{data.customIntro}</p>
        </div>}
        <div className="XcChallengeSummary_description">
            <h3>Descrierea Activității</h3>
            <p>{data.description}</p>
        </div>
    </div>;
};

export default XcChallengeSummary;
