import React from 'react';
import { __ } from '../../i18n';
import './style.scss';

const How = (props) => {
    const { regionCode } = props;

    return <div id="how" className="WhiteSection How">
        <div className="How__contentCell">
            <div className="How__content">
                <h2>{__('Everyday activities transformed into meaningful moments')}</h2>
                <h3>{__('Get started with over 100+ personalised, practical & playful activities for 10-15 minutes a day.')}</h3>
                <ul>
                    <li>
                        <i>1</i>
                        <div>
                            <h4>{__('Create | Beautiful Memories ')}</h4>
                            <p>{__(`Access a wide variety of learning experiences and meaningul moments from daily conversations, experiments, games, short stories and collaborative and creative activities that can be done anytime, anywhere. `)}</p>
                        </div>
                    </li>
                    <li>
                        <i>2</i>
                        <div>
                            <h4>{__(`Grow | Learning through Play`)}</h4>
                            <p>{__(`Once you have decided on an activity, your guides will take you through the activity step by step and offer support along the way!`)}</p>
                        </div>
                    </li>
                    <li>
                        <i>3</i>
                        <div>
                            <h4>{__(`Capture | Quality time Together`)}</h4>
                            <p>{__(`Upload or take a picture to capture your child's development and progress. Journal the important milestones and relive your special moments.`)}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div className="How__image">
            <div style={{ width: '100%', height: '100%', backgroundColor: 'pink' }}><img src={`/images/home/how_${regionCode}.png`} alt={__('How')} /></div>
        </div>
    </div>;
}

export default How;