import React, { useState, useContext } from 'react';
import ReactGA from "react-ga4";
import classNames from 'classnames';
import { __ } from '../../i18n';
import DefaultHeader from '../../components/DefaultHeader';
import DefaultFooter from '../../components/DefaultFooter';
import RegionContext from '../../contexts/RegionContext';
import ImageNoPlay from '../../vectors/home/noplay.svg'
import ImageHappyChildhood from '../../vectors/home/happychildhood.svg';
import ImagePromo from '../../vectors/home/promo.svg';
import EarlyBrain from '../../vectors/home/earlybrain.svg';
import { Enquire } from '../../Home';
import '../../Home.scss';

const Page1 = (props) => {
    const region = useContext(RegionContext);

    return <>
        <div className="Home">
            <div className="WhiteSection HomeSection">
                <DefaultHeader />
            </div>
      <div className="InsideBanner GradientSection">
        <div className="InsideBannerContent">
            <div className="InsideBannerContent__details">
             <h1>Aplicația ONE Learning</h1>
              <h1>#15minutedejoacă</h1>
              <h2>Înscrie-te pentru a fii printre primele persoane care încearcă aplicația ONE Learning și de a accesa peste 100+ de activități simple, jucăușe și personalizate pentru familia ta!</h2>
            </div>
            <div className="InsideBanner__img">
              <img alt="Play with star" src='/images/activities/star.png' height="100%" />
            </div>
        </div>
      </div>
      <div id="whyplay" className={"WhiteSection"}>
        <div className="Content OurMission">
          <div className="SideImage">
            <div className="ImageSide">
            <img alt="Play with star" src='/images/activities/phone.png' width="400px" />
            </div>
            <div className="TextSide">
              <h1>Activitățile de zi cu zi transformate în momente memorabile</h1>
              <p><h2>Cauți o modalitate distractivă și interactivă de a-ți ajuta copilul/copiii să își dezvolte abilități esențiale pentru școală și viață? Aplicația noastră combină învățarea cu jocul, aducând experiențe (off-screen) personalizate și captivante pentru copii (3-8 ani).</h2></p>
              <p>
              Cu aplicația noastră, poți explora o varietate de jocuri și activități concepute pentru a-i ajuta pe copii să își dezvolte armonios abilități importante, cum ar fi dezvoltarea abilităților cognitive, fizice, creative, socio-emoționale, de rezolvare a problemelor, creativitatea și comunicarea (dezvoltarea ligvistică).
              </p>
              <p>
              Dar asta nu este tot! Aplicația noastră permite, de asemenea, să sortezi activitățile / jocurile din aplicație în funcție de vârstă, timpul și materialele pe care le ai la dispoziție - salvând timpul prețios pentru a te conecta și crea amintiri frumoase cu copilul/copii tăi! Înscrie-te mai jos ca să nu ratezi lansarea!
              </p>                              
            </div>
          </div>
        </div>
      </div>
      <Enquire title="" region={region} />
      <div id="successLookLike" className={"WhiteSection"}>
        <div className="Content OurMission">
          <div className="SideImage">
            <div className="TextSide">
              <h1>{__(`Ceea ce se întâmplă în copilăria noastră ajută la crearea fundației pentru sănătatea și fericirea noastră viitoare.`)}</h1>
              <h2>{__('De ce #15minutedejoacă?')}</h2>
              <p>
              {__(`Așa cum conversația este imporantă pentru adulți pentru a reflecta, învăța, împârtăși și a înțelege ce se întâmplă zi de zi - la fel de importantă este joaca pentru copii - este limbajul lor universal prin care descoperă lumea, pe ei înșiși și comunică cu noi zi de zi.`)}
              </p>
              <p>
              {__(`Creierul copiilor este în continuă dezvoltare, iar primii ani de viață formează fundația noastră. Atunci când se joacă, creierul formează mai mult de un milion de conexiuni neronale în fiecare secundă. Aceste momente semnificative, oferă o bază solidă pentru fiecare copil - doar 15minutedejoacă pe zi!  `)}
              </p>
              </div>
            <div className="ImageSide">
              <img width="450px" src={EarlyBrain} alt={__('Children deserve a happy childhood')} />
            </div>
          </div>
        </div>
      </div>
            <DefaultFooter />
        </div>
    </>;
}

export default Page1;
