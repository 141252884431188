import React from "react";
import { Card, Row, Col, Avatar, Button } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";


export function DefaultDescription({ user }) {

    return (
        <React.Fragment>
            <Row justify="center" style={{ paddingBottom: '.9em' }}>
                <Avatar src="images/keyvector.svg" size={60} />
            </Row>
            <Row justify="center">
                <p >{user}</p>
            </Row>
            <Row justify="center">
                <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Set New password</p>
            </Row>
            <Row justify="center">
                <Col span={12}>
                    <p style={{ textAlign: 'center' }} >Set a new password</p>
                </Col>
            </Row>
        </React.Fragment>
    )

}

export function NoOrganization({ user }) {

    return (
        <React.Fragment>
            <Row justify="center" style={{ paddingBottom: '.9em' }}>
                <Avatar src="images/keyvector.svg" size={60} />
            </Row>
            <Row justify="center">
                <p >{user}</p>
            </Row>
            <Row justify="center">
                <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Organisation not found</p>
            </Row>
            <Row justify="center">
                <Col span={12}>
                    <p style={{ textAlign: 'center' }} >Sorry, it looks like the organization you are trying to access does not exist in our system. Please double check the organization name and try again. If you believe you have received this message in error, please contact our support team for assistance.</p>
                </Col>
            </Row>
        </React.Fragment>
    )

}

export function NoUserEmail({ user }) {

    return (
        <React.Fragment>
            <Row justify="center" style={{ paddingBottom: '.9em' }}>
                <Avatar src="images/keyvector.svg" size={60} />
            </Row>
            <Row justify="center">
                <p >{user}</p>
            </Row>
            <Row justify="center">
                <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Email not found!</p>
            </Row>
            <Row justify="center">
                <Col span={12}>
                    <p style={{ textAlign: 'center' }} >We could not find a user with the provided email address. Please check the email address and try again, or contact our support team for assistance.</p>
                </Col>
            </Row>
        </React.Fragment>
    )

}


export function EmptyPassword({ user }) {

    return (
        <React.Fragment>
            <Row justify="center" style={{ paddingBottom: '.9em' }}>
                <Avatar src="images/keyvector.svg" size={60} />
            </Row>
            <Row justify="center">
                <p >{user}</p>
            </Row>
            <Row justify="center">
                <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Password is Empty!</p>
            </Row>
            <Row justify="center">
                <Col span={12}>
                    <p style={{ textAlign: 'center' }} >Please provide a password and try again.</p>
                </Col>
            </Row>
        </React.Fragment>
    )

}


export function password5Char({ user }) {

    return (
        <React.Fragment>
            <Row justify="center" style={{ paddingBottom: '.9em' }}>
                <Avatar src="images/keyvector.svg" size={60} />
            </Row>
            <Row justify="center">
                <p >{user}</p>
            </Row>
            <Row justify="center">
                <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Invalid Password!</p>
            </Row>
            <Row justify="center">
                <Col span={12}>
                    <p style={{ textAlign: 'center' }} >Password lenght is less thant 5 characters.</p>
                </Col>
            </Row>
        </React.Fragment>
    )

}

export function UnknownException() {

    return (
        <React.Fragment>
            <Row justify="center" style={{ paddingBottom: '.9em' }}>
                <Avatar src="images/keyvector.svg" size={60} />
            </Row>
            {/* <Row justify="center">
                <p >{user}</p>
            </Row> */}
            <Row justify="center">
                <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Unknown Exception!</p>
            </Row>
            <Row justify="center">
                <Col span={12}>
                    <p style={{ textAlign: 'center' }} >An unknown error occurred while processing your request to reset your password. Please try again later or contact our support team for assistance.</p>
                </Col>
            </Row>
        </React.Fragment>
    )

}

export function UserNotActive({ user }) {

    return (
        <React.Fragment>
            <Row justify="center" style={{ paddingBottom: '.9em' }}>
                <Avatar src="images/keyvector.svg" size={60} />
            </Row>
            <Row justify="center">
                <p >{user}</p>
            </Row>
            <Row justify="center">
                <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>User not Activated</p>
            </Row>
            <Row justify="center">
                <Col span={12}>
                    <p style={{ textAlign: 'center' }} >We could not find an active user with the provided email address that is assigned to the organization. Please check the email address and ensure the user is active and belongs to the organization, or contact our support team for assistance.</p>
                </Col>
            </Row>
        </React.Fragment>
    )

}

export function RequestProcessed({ user }) {

    return (
        <React.Fragment>
            <Row justify="center" style={{ paddingBottom: '.9em' }}>
                <Avatar src="images/keyvector.svg" size={60} />
            </Row>
            <Row justify="center">
                <p >{user}</p>
            </Row>
            <Row justify="center">
                <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Request Processed</p>
            </Row>
            <Row justify="center">
                <Col span={12}>
                    <p style={{ textAlign: 'center' }} >Your request to reset your password has already been processed. Please request a new password reset and follow the instructions provided in the email.</p>
                </Col>
            </Row>
        </React.Fragment>
    )

}


export function UserDeleted({ user }) {

    return (
        <React.Fragment>
            <Row justify="center" style={{ paddingBottom: '.9em' }}>
                <Avatar src="images/keyvector.svg" size={60} />
            </Row>
            <Row justify="center">
                <p >{user}</p>
            </Row>
            <Row justify="center">
                <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>User not found</p>
            </Row>
            <Row justify="center">
                <Col span={12}>
                    <p style={{ textAlign: 'center' }} >We could not find an active user with the provided email address. If you believe this is an error, please contact our support team for assistance.</p>
                </Col>
            </Row>
        </React.Fragment>
    )

}

export function PreviousPassword({ user }) {

    return (
        <React.Fragment>
            <Row justify="center" style={{ paddingBottom: '.9em' }}>
                <Avatar src="images/keyvector.svg" size={60} />
            </Row>
            <Row justify="center">
                <p >{user}</p>
            </Row>
            <Row justify="center">
                <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Set New password</p>
            </Row>
            <Row justify="center">
                <Col span={12}>
                    <p style={{ textAlign: 'center' }} >The password you have entered is the same as a previous password. Please choose a new, unique password and try again.</p>
                </Col>
            </Row>
        </React.Fragment>
    )

}

export function ActionFailed() {

    return (
        <React.Fragment>
            <Row justify="center" style={{ paddingBottom: '.9em' }}>
                <Avatar src="images/keyvector.svg" size={60} />
            </Row>
            {/* <Row justify="center">
                <p >{user}</p>
            </Row> */}
            <Row justify="center">
                <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Action failed</p>
            </Row>
            <Row justify="center">
                <Col span={12}>
                    <p style={{ textAlign: 'center' }} >We were unable to process your request at this time. Please try again later or contact our support team for assistance.</p>
                </Col>
            </Row>
        </React.Fragment>
    )

}

export function InvalidRequestTimedOut() {

    const navigate = useNavigate();

    const goHome = () => {
        try {

            navigate(`/`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }

    return (
        <React.Fragment>
            <div>
                <Row justify='center'>
                    <Col span={16}>

                        <Row justify='center' style={{ padding: '1em' }}>
                            <Avatar src="images/cautionvector.svg" size={60} />
                        </Row>
                        <Row justify="center">
                            <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Oops! Something is not right</p>
                        </Row>
                        <Row justify='center'>
                            <Col>
                                <p style={{ textAlign: 'center' }}>Forgot password request might have expired, please make forget password request again or contact us.</p>
                            </Col>
                        </Row>
                        <Row justify='center' style={{ padding: '1em', paddingBottom: '2em' }}>
                            <Button onClick={goHome} type="link" icon={<ArrowLeftOutlined />}  >go to Home</Button>
                        </Row>

                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )

}

export function InvalidRequest() {

    const navigate = useNavigate();

    const goHome = () => {
        try {

            navigate(`/`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }

    return (
        <React.Fragment>
            <div>
                <Row justify='center'>
                    <Col span={16}>

                        <Row justify='center' style={{ padding: '1em' }}>
                            <Avatar src="images/cautionvector.svg" size={60} />
                        </Row>
                        <Row justify="center">
                            <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Oops! Something is not right</p>
                        </Row>
                        <Row justify='center'>
                            <Col>
                                <p style={{ textAlign: 'center' }}>The request to reset your password is invalid. Please request a new password reset and follow the instructions provided in the email.</p>
                            </Col>
                        </Row>
                        <Row justify='center' style={{ padding: '1em', paddingBottom: '2em' }}>
                            <Button onClick={goHome} type="link" icon={<ArrowLeftOutlined />}  >go to Home</Button>
                        </Row>

                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )

}

export function PasswordSet() {

    const navigate = useNavigate();

    const goHome = () => {
        try {

            navigate(`/`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }

    return (
        <React.Fragment>
            <div>
                <Row justify='center'>
                    <Col span={12}>

                        <Row justify='center' style={{ padding: '1em' }}>
                            <Avatar src="images/tickvector.svg" size={60} />
                        </Row>
                        <Row justify="center">
                            <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Password set</p>
                        </Row>
                        <Row justify='center'>
                            <Col>
                                <p style={{ textAlign: 'center' }}>Your password has been successfully reset. Please use your new password to log in to the App.</p>
                            </Col>
                        </Row>
                        <Row justify='center' style={{ padding: '1em' }}>
                            <Button onClick={goHome} type="link" icon={<ArrowLeftOutlined />}  >go to Home</Button>
                        </Row>

                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )

}