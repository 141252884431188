import React, { useState, useContext } from 'react';
import ReactGA from "react-ga4";
import classNames from 'classnames';
import { __ } from '../../i18n';
import DefaultHeader from '../../components/DefaultHeader';
import DefaultFooter from '../../components/DefaultFooter';
import RegionContext from '../../contexts/RegionContext';
import ImageNoPlay from '../../vectors/home/noplay.svg'
import ImageHappyChildhood from '../../vectors/home/happychildhood.svg';
import '../../Home.scss';

const Page1 = (props) => {
    const region = useContext(RegionContext);

    return <>
        <div className="Home">
            <div className="WhiteSection HomeSection">
                <DefaultHeader />
            </div>
            <div id="whyplay" className={"WhiteSection"}>
        <div className="Content OurMission">
          <div className="SideImage">
            <div className="ImageSide">
              <img width="400px" src={ImageNoPlay} alt={__('Effects of no play')} />
            </div>
            <div className="TextSide">
              <h1>{__(`What happens when children don't play`)}</h1>
              <p>
              {__('Every child has an natural instinct for play. For adults, play means leisure, but for children, play is more like their job. They love their work and seldom want a day off.')}
              </p>
              <p>{__('There are long-term consequences for children whose development is not adequately supported in the early years: poor developmental outcomes in early childhood are associated with poor outcomes in adulthood.')}</p>
              <p>
              {__(`When children don't play or being exposed to diverse experiences, they may miss out on important milestones for physical, cognitive, mental and social-emotional development. This could also lead to social isolation, lack of creativity, poor physical and emotional health.`)}
              </p>
              <p> 
              {__(`Play is children's main way of communicating, of experimenting, and of learning.Play allows children to explore and experiement, develop skills for life and learn to commnunicate and cooperate with others.`)} 
              </p>
              
            </div>
          </div>
        </div>
      </div>
      <div id="successLooksLike" className={"WhiteSection"}>
        <div className="Content OurMission">
          <div className="SideImage">
            <div className="TextSide">
              <h1>{__(`Every child deserves a happy childhood and a bright future`)}</h1>
              <h2>{__('Play is the essence of life')}</h2>
              <p>
              {__(`Many experts describe play as a place - a place of magic and imagination, a place where a child can be fully one's self. Play is fun, but also meaningful and complex.`)}
              </p>
              <p>
              {__(`Some jobs will disappear, others will grow, and jobs that don’t even exist today will become the norm. Although we can’t predict with certainty the types of jobs that will exist in the future. `)}
              </p>
              <p> 
              {__(`Today’s children will need a variety of skills to thtive in an unpredictable world. The guides are here to help empower children to unlock their essential skills for life, anytime, anywhere through play.`)}
              </p>
            </div>
            <div className="ImageSide">
              <img width="500px" src={ImageHappyChildhood} alt={__('Children deserve a happy childhood')} />
            </div>
          </div>
        </div>
      </div>
            <DefaultFooter />
        </div>
    </>;
}

export default Page1;
