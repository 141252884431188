import React from 'react';
import { __ } from '../../i18n';
import DefaultHeader from '../../components/DefaultHeader';
import DefaultFooter from '../../components/DefaultFooter';
import '../../Home.scss';
import './style.scss';

const Page1 = (props) => {
    // const region = useContext(RegionContext);
    return <>
        <div className="Home AboutUs">
            <div className="WhiteSection HomeSection">
                <DefaultHeader />
            </div>
       <div className={"WhiteSection AboutUsTop"}>
        <div className="Content OurMission">
          <div className="SideImage">
            <div className="TextSide">
              <h1>Despre noi</h1>
              <h2>Viziune | de ce-ul nostru</h2>
              <p>Ne imaginăm o lume în care toți copiii au o copilărie fericită și un viitor luminos indiferent de mediul de unde provin. Ne dorim să creăm peste 1m de momente autentice de conectare prin joacă între părinți și copiii lor.</p>
              <h2>Misiune | cum facem acest lucru</h2>
              <p>Misiunea nostră este să sprijinim dezvoltarea relațiilor receptive, armonioase și hrănitoare între copiii mici și părinții sau îngrijitorii lor prin access la resurse educaționale de calitate.</p>
              <h2>Valorile noastre</h2>
              <p>Echipa, partenerii și colaboratorii noștrii se regăsesc în valorile care ne ghidează orice acțiune și decizie: învățare prin experiență, compasiune, diversitate, excelență și integritate.</p>            
              <p></p><p></p>
            </div>
            <div className="ImageSide">
              <img width="400px" src="/images/aboutus/star_about.jpg" alt={__('Effects of no play')} />
            </div>
          </div>
        </div>
      </div>
      <div id="x11111" className={"GradientSection AboutUsImpact"}>
        <div className="Content OurMission">
          <div className="SideImage">
            <div className="TextSide">
              <h1>Impact</h1>
              <p>Modelul nostru de teorie a schimbării vizează îmbunătățirea conexiunii dintre copii și părinți - punând în centru importanța cultivării relațiilor, a comunicării și a legăturilor emoționale pozitive dintre părinți și copii.</p>
              <p>Oferim instrumentele, cunoștințele și sprijinul pentru a fi un părinte mai încrezător și pentru a înțelege mai bine modul unic în care copilul lor învață și se dezvoltă.</p>
              <h2>Facem acest lucru prin 3 piloni principali:</h2>
              <h3>(1) Promovarea bunăstării familiei </h3><p>Construirea relațiilor armonioase părinte-copil, pentru a crea un mediu sigur, care stimulează creșterea.</p>
              <h3>(2) Acces la o comunitate de sprijin pentru părinți</h3><p>Strategii de dezvoltare și parenting care îmbogățesc mediul de învățare și sporesc dezvoltarea.</p>
              <h3> (3) Acces la resurse educaționale de calitate </h3><p>Resurse care să ajute la crearea de conexiuni pozitive cu materiale găsite în casă și ușor de incorporat în viața de zi cu zi.</p>
              <h3> Impactul pe termen lung </h3><p>Dezvoltarea abilităților esențiale de viață a copiilor pentru școală și viață </p>
              <p></p>
            </div>
            <div className="ImageSide">
              <img width="400px" src="/images/aboutus/impact.png" alt={__('Children deserve a happy childhood')} />
              <a rel='noreferrer' href='https://ideas.developingchild.harvard.edu/' target='_blank' style={{ display: 'inline-block', padding: '10px 0 5px 0', borderRadius: '5px', overflow: 'hidden', backgroundColor: '#fff' }}>
                <img width="400px" src="/images/aboutus/toclogo.jpg" alt={__('Children deserve a happy childhood')} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={"AboutUsTeamSection WhiteSection"}>
        <div className="Content OurMission">
            <h1>Echipa ONE Learning</h1>
            <h2>{__('Play is the essence of life')}</h2>
           <p> Copiii de astăzi vor avea nevoie de o varietate de abilități pentru a face față unei lumi în continuă schimbare și imprevizibilă. Am creat acastă aplicație pentru a ajuta copiii să-și dezvolte abilitățile esențiale pentru viață, oricând și oriunde prin joacă.</p>

        </div>
        <ul className="AboutUsTeam">
          <li>
            <img className="AboutUsTeam__img" src="/images/aboutus/Azhar.jpg" alt={__('Azhar Xec')} />
            <span className="AboutUsTeam__name">Azhar Xec</span>
            <span className="AboutUsTeam__title">Engineering<br/>Cloud/Backend</span>
          </li>
          <li>
            <img className="AboutUsTeam__img" src="/images/aboutus/Ioana.jpg" alt={__('Ioana Axinte')} />
            <span className="AboutUsTeam__name">Ioana Axinte</span>
            <span className="AboutUsTeam__title">CEO</span>
          </li>
          <li>
            <img className="AboutUsTeam__img" src="/images/aboutus/steve.jpg" alt={__('Steve Fernandes')} />
            <span className="AboutUsTeam__name">Steve Fernandes</span>
            <span className="AboutUsTeam__title">Engineering<br/>UX/Frontend</span>
          </li>
          <li>
            <img className="AboutUsTeam__img" src="/images/aboutus/Ligia.jpg" alt={__('Ligia')} />
            <span className="AboutUsTeam__name">Ligia</span>
            <span className="AboutUsTeam__title">Digital Marketing</span>
          </li>
        </ul>
      </div>
      <div id="x11111" style={{ display: 'none' }} className={"GradientSection"}>
        <div className="Content OurMission">
              <h1>Consiliul Director</h1>
              <h2>{__('Play is the essence of life')}</h2>
              <p>
              {__(`Many experts describe play as a place - a place of magic and imagination, a place where a child can be fully one's self. Play is fun, but also meaningful and complex.`)}
              </p>
              <p>
              {__(`Some jobs will disappear, others will grow, and jobs that don’t even exist today will become the norm. Although we can’t predict with certainty the types of jobs that will exist in the future. `)}
              </p>
              <p> 
              {__(`Today’s children will need a variety of skills to thtive in an unpredictable world. The guides are here to help empower children to unlock their essential skills for life, anytime, anywhere through play.`)}
              </p>
        </div>
      </div>
      <DefaultFooter />
        </div>
    </>;
}

export default Page1;
